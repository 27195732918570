import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useCreateSubmitAssignmentMutation, useCreateSubmitTutorialMutation, useGetAssignmentByIdQuery, useGetAssignmentsQuery, useGetUserDetailsQuery, usePatchSubmitAssignmentMutation, usePatchSubmitTutorialMutation } from '../../services/userSlice';
import Loading from '../../components/ui/Loading';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import childimg from '../../assets/images/bookspost.png';
import bookrightimg1 from '../../assets/images/bookrightpage.png';
import bookrightimg2 from '../../assets/images/bookrightpage2.png';
import spiralimg from "../../assets/images/spiral.png";
import wait from "../../assets/images/wait.png";
import wait2 from "../../assets/images/wait2.png";
import ViewSubmitModal from '../../components/modals/ViewSubmitModal';
import SuccessModal from '../../components/modals/SuccessModal';
import Api from '../../constants/Api';

const Assignment = () => {
    const { id } = useParams();

    const [createAbout, createAboutRes] = useCreateSubmitAssignmentMutation();
    const [tutorialSubmit, tutorialSubmitRes] = useCreateSubmitTutorialMutation();
    const [createAboutUpdate, createAboutResUpdate] = usePatchSubmitAssignmentMutation();
    const [tutorialSubmitUpdate, tutorialSubmitResUpdate] = usePatchSubmitTutorialMutation();

    const response = useGetAssignmentsQuery();
    const userRes = useGetUserDetailsQuery();

    const currAssignment = useGetAssignmentByIdQuery(id);

    if (response.isLoading || userRes.isLoading || currAssignment.isLoading) return <Loading />;
    if (response.isError) return <ApiErrorModal response={response} />;

    return (
        <div className='container-fluid py-3 h-100 pb-5 overflow-y-auto overflow-x-hidden'>
            {createAboutRes.isSuccess && <SuccessModal label={"Assignment Submitted!"} message={"Assignment Submitted!"} closeTo={''} />}
            {createAboutResUpdate.isSuccess && <SuccessModal label={"Assignment Submission Updated!"} message={"Assignment Submission Updated!."} closeTo={''} />}


            <style>
                {`
            img.jelloring {
                object-fit: cover;
                margin-top: -62px;
                height: 50px;
                border-radius: 0 !important;
            }
            .card.notebookleft {
                background: #fff;
                padding: 30px;
                border-radius: 20px;
                box-shadow: 5px 5px 80px -45px #000;
                display: flex;
                flex-direction: column;
                height: 100%;
                position: relative;
            }
            .card.notebookleft.watermarkpage:before {
                content: '';
                display: block;
                height: 90%;
                width: 50px;
                background: url(https://i.imgur.com/l0ecd2d.png);
                background-size: contain;
                position: absolute;
                left: -36px;
                top: 30px;
                background-repeat: repeat-y;
            }
            .card.notebookleft .notebookleftcont {
                flex-grow: 1;
                padding: 15px;
                max-height: 250px;
                overflow-y: auto;
            }
            .card.notebookleft h2 {
                text-align: center;
                font-family: 'poppins',sans-serif;
                color: #de0b0b;
                font-weight: 600;
                font-size: 25px;
                margin-bottom: 10px;
            }
            .card.notebookleft img {
                border-radius: 20px;
                width: 100%;
                margin-bottom: 20px;
            }
            img.notebookwatermark {
                margin: -30px;
                width: -webkit-fill-available !important;
                margin-top: 20px;
            }
            .card.notebookleft.watermarkpage h3 {
                font-family: fangsong;
                text-align: center;
                font-size: 25px;
            }
            @media(max-width: 767.20px){
                .card.notebookleft.watermarkpage:before{
                    display: none;
                }
                .card.notebookleft {
                    height: auto;
                    margin: 8px 0;
                }
                .card.notebookleft.watermarkpage {
                    margin-bottom: 30px;
                }
            }
            .btn-grp.notepadgrp {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .btn-grp.notepadgrp a.notepadgrpbtns {
                background: #000;
                padding: 10px;
                border-radius: 45px;
                min-height: 80px;
                min-width: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 12px;
            }
            .btn-grp.notepadgrp a.notepadgrpbtns img {
                margin: 0;
                max-width: 35px;
            }
            .btn-grp.notepadgrp a.notepadgrpbtns.yelbtn {
                background: #F5BE02;
                margin-bottom: -140px;
            }
            .btn-grp.notepadgrp a.notepadgrpbtns.blbtn {
                background: #2A71BF;
            }
            .btn-grp.notepadgrp a.notepadgrpbtns.pnkbtn {
                background: #F335CF;
            }
            .btn-grp.notepadgrp a.notepadgrpbtns.grnbtn {
                background: #77AD44;
                margin-bottom: -140px;
            }
            .textdesnotebook {
                margin: 20px 0;
            }
            .textdesnotebook p {
                font-size: 20px;
                font-family: 'Poppins',sans-serif;
                text-align: center;
                margin: 0;
                text-transform: uppercase;
            }
            .textdesnotebook h3 {
                font-size: 45px !important;
                font-family: 'Poppins',sans-serif !important;
                margin: 0;
                text-transform: uppercase;
                font-weight: 700;
                margin-top: -5px;
            }
           
          `}
            </style>


            <div className="row d-flex h-100">
                <div className="col-12 col-md-8 p-4 ">
                    <div className="container  h-100">
                        <div className="row " >
                            <div className="col-md-6 ">
                                <div className="card notebookleft ">
                                    <h2 dangerouslySetInnerHTML={{ __html: currAssignment.data.assignmentName }} className='text-danger fw-bold mb-3'></h2>

                                    <div>
                                        <img src={Api.IMAGE_URL + currAssignment?.data?.image?.id + currAssignment?.data?.image?.ext} className='text-center w-100' alt="" />
                                    </div>

                                    <div className="notebookleftcont">
                                        <h3 className='text-danger fw-bold'>Outline</h3>
                                        <div dangerouslySetInnerHTML={{ __html: currAssignment.data.pageTitle }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card notebookleft watermarkpage">
                                    <img src="https://i.imgur.com/KONVAkc.png" className="jelloring d-flex d-md-none d-lg-none d-xl-none" />

                                    <div className="btn-grp notepadgrp">
                                        <a className="notepadgrpbtns yelbtn"><img src="https://i.imgur.com/gnAsOUM.png" /></a>
                                        <a className="notepadgrpbtns blbtn"><img src="https://i.imgur.com/LfdJjsh.png" /></a>
                                        <a className="notepadgrpbtns pnkbtn"><img src="https://i.imgur.com/dSaacGO.png" /></a>
                                        <a className="notepadgrpbtns grnbtn"><img src="https://i.imgur.com/Qt7d31G.png" /></a>
                                    </div>

                                    <div className="textdesnotebook d-flex flex-column justify-content-center align-items-center ">
                                        <p>Your Each Seva</p>
                                        <h3>Counts</h3>

                                        <button className="btn btn-sm btn-primary w-50  px-3 rounded-pill my-3 " style={{ zIndex: 20 }} data-bs-toggle="modal" data-bs-target={"#" + "ViewSubmitModal"}>submit</button>

                                        <ViewSubmitModal id={id} createAbout={createAbout} tutorialSubmit={tutorialSubmit} createAboutUpdate={createAboutUpdate} tutorialSubmitUpdate={tutorialSubmitUpdate} createAboutRes={createAboutRes} tutorialSubmitRes={tutorialSubmitRes} />

                                    </div>

                                    <img src="https://i.imgur.com/zJqRLes.png" className="notebookwatermark" />
                                    <h3>We Are More Powerful When We Empower Each Other!</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-4  my-4 ps-3 h-100 pe-0 hide-scroll">
                    <div className='bg-white shadow overflow-hidden rounded-start-5 h-100 d-flex flex-column'>
                        <div className='p-3 bg-info text-danger fw-bold'>Assignments</div>
                        <div className='flex-grow overflow-auto'>
                            {
                                response.data.map((assignment) =>
                                    <div className='p-3 border-bottom' key={assignment.id}>
                                        <Link to={`/assignments/${assignment.id}`} className='fw-bold text-decoration-none'>{assignment.assignmentName}</Link>
                                        {
                                            assignment.submissions.filter((item) => item.userId == userRes.data.id).length > 0 ?
                                                <div className='d-flex gap-1 align-items-center text-success my-1'>
                                                    <img src={wait2} />
                                                    <p>Submitted</p>
                                                </div>
                                                :
                                                <div className='d-flex gap-1 align-items-center text-danger my-1'>
                                                    <img src={wait} />
                                                    <p>Pending</p>
                                                </div>
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Assignment;





