import React, { useState } from "react";
import logo from "../../assets/gif/portals.gif";
import Input from "../../components/form/Input";
import InputTextArea from "../../components/form/InputTextArea";
import Icons from "../../components/ui/Icons";
import {
  useCreateSupportTicketMutation,
  useGetSupportTicketsQuery,
} from "../../services/supportSlice";
import SuccessModal from "../../components/modals/SuccessModal";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import Loading from "../../components/ui/Loading";
import SupportAction from "./SupportAction";
import ticketimg from "../../assets/images/ticket.png";
import bottomimg from "../../assets/images/goldenbottom.png";

const Support = () => {
  const supportTicketsRes = useGetSupportTicketsQuery();
  const [createSupportTicket, createSupportTicketRes] =
    useCreateSupportTicketMutation();

  const [selectedTicket, setSelectedTicket] = useState(null);

  if (supportTicketsRes.isLoading || createSupportTicketRes.isLoading)
    return <Loading />;

  if (supportTicketsRes.isError || createSupportTicketRes.isError) {
    return <div>Error...</div>;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    createSupportTicket({
      topic: e.target["Topic"].value,
      message: e.target["Message"].value,
    }).then((res) => {
      if (!res.error) {
        supportTicketsRes.refetch();
      }
    });
  };

  return (
    <div className="container-fluid px-4 py-3 h-100 hide-scroll ">
      {createSupportTicketRes.isSuccess && (
        <SuccessModal message={"Ticket has been created."} />
      )}
      {createSupportTicketRes.isError && (
        <ApiErrorModal response={createSupportTicketRes} />
      )}

      <div className="row p-3 h-100">
        <div className="col-8  hide-scroll d-flex flex-column  overflow-auto h-100">
          <div className="d-flex position-relative h-50">
            <div className="bg-primary p-5 rounded-start-5 w-75">
              <h3 className="fw-bold text-white">Support</h3>
              <p className="text-white  text-wrap opacity-50">
                Need help ? feel free to contact us. <br />
                (all days 9 am to 9 pm IST)
              </p>
              <h5 className="fw-bold mt-3 text-white">WhatsApp Us</h5>
              <p className="mb-3 text-white opacity-50">+91- 9099090990</p>
              <h5 className="fw-bold mt-3 text-white">Email Us</h5>
              <p className="mb-3 text-white opacity-50">icgs@sgrs.org</p>
              <h5 className="fw-bold mt-3 text-white">Call Us</h5>
              <p className="mb-3 text-white opacity-50">+91- 9099090990</p>
            </div>

            <div className="p-3 rounded-5 shadow bg-white flex-grow-1 position-absolute w-50 end-0 h-100">
              <form className="" onSubmit={onSubmit}>
                <div className="text-center">
                  <img src={logo} width={250} alt="" className="mt-3 mb-5 " />
                </div>
                <Input name={"Topic"} containerClass={"mb-3"} required />
                <InputTextArea name={"Message"} required />
                <button
                  type="submit"
                  className="btn btn-danger rounded-pill text-white my-3"
                >
                  Raise Ticket
                </button>
              </form>
            </div>
          </div>

          <div className=" hide-scroll flex-grow-1  mt-4 h-100 ">
            <div className="rounded-4 bg-primary mx-3 p-3">
              <h2 className="text-white fw-bold">Tickets</h2>
            </div>

            <div className="overflow-auto container p-0 h-100">
              {supportTicketsRes.data?.map((item) => (
                <div
                  className="row row-cols-5 d-flex align-items-center bg-white flex-grow-1 rounded-4 m-3 shadow "
                  key={item.id}
                >
                  <div className="col-2">
                    <div className="py-3 border-end  ">
                      <div className="fw-bold">Ticket ID</div>
                      <p className="fs-11 text-muted">{item.id}</p>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="py-3  border-end ">
                      <h6 className="fw-bold">Topic</h6>
                      <p className="fs-11 text-muted">
                        {item.topic.length > 15
                          ? item.topic.substring(0, 14) + "..."
                          : item.topic}
                      </p>
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="py-3 border-end">
                      <h6 className="fw-bold">Last Updated</h6>
                      <p className="fs-11 text-muted">
                        {item.updatedAt &&
                          new Date(item.updatedAt).toLocaleDateString()}
                      </p>
                    </div>
                  </div>

                  <div className="col-2 ">
                    <div className="py-3  border-end">
                      {item.status == "Created" ? (
                        <div
                          className="btn btn-sm  rounded-pill text-white"
                          style={{ backgroundColor: "red" }}
                        >
                          Unresolve
                        </div>
                      ) : (
                        <div
                          className="btn btn-sm  rounded-pill text-white"
                          style={{ backgroundColor: "#508D02" }}
                        >
                          Resolved
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-1">
                    <div className="py-3 text-center">
                      <div
                        className="btn btn-outline-success border-0 p-0 rounded-circle text-hover-white"
                        onClick={() => setSelectedTicket(item.id)}
                      >
                        {Icons.arrow()}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="col-4  h-100">
          {selectedTicket ? (
            <SupportAction id={selectedTicket} />
          ) : (
            <div className="w-100 h-100 bg-white d-flex flex-column overflow-hidden rounded-5 shadow">
              <div className="fs-3 fw-bold bg-light ps-4 py-1 ">
                <span className="text-danger">Query </span>Tickets
              </div>
              <div className="text-center p-3 flex-grow-1 d-flex justify-content-center align-items-center h-100">
                <div className="w-100">
                  <div className=" py-4">
                    {" "}
                    <img src={ticketimg} style={{ width: 250 }} alt="" />
                  </div>
                  <div className="">
                    Effortlessly resolve your queries by creating query tickets,
                    and we'll assist you in every possible way.
                  </div>
                  <div className=" fs-13 text-muted py-4">
                    Please be patient as ticket replies may take some time. In
                    urgent cases, connect with the SGRS Utility service provider
                    or Brahmvidhya Vibhag for a swift solution. Your
                    understanding is greatly appreciated.
                  </div>
                  <div>
                    <img src={bottomimg} alt="" style={{ width: 250 }} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="py-5 ps-3 container-fluid d-flex justify-content-between hide-scroll h-100">
      {createSupportTicketRes.isSuccess && (
        <SuccessModal message={"Ticket has been created."} />
      )}
      {createSupportTicketRes.isError && (
        <ApiErrorModal response={createSupportTicketRes} />
      )}

      <div
        className="hide-scroll d-flex flex-column flex-grow- mx-4 overflow-auto h-100"
        style={{ width: "60%" }}
      >
        <div className="d-flex position-relative h-50">
          <div className="bg-primary p-5 rounded-start-5 w-75">
            <h3 className="fw-bold text-white">Support</h3>
            <p className="text-white  text-wrap opacity-50">
              Need help ? feel free to contact us. <br />
              (all days 9 am to 9 pm IST)
            </p>
            <h5 className="fw-bold mt-3 text-white">WhatsApp Us</h5>
            <p className="mb-3 text-white opacity-50">+91- 9099090990</p>
            <h5 className="fw-bold mt-3 text-white">Email Us</h5>
            <p className="mb-3 text-white opacity-50">icgs@sgrs.org</p>
            <h5 className="fw-bold mt-3 text-white">Call Us</h5>
            <p className="mb-3 text-white opacity-50">+91- 9099090990</p>
          </div>

          <div className="p-3 rounded-5 shadow bg-white flex-grow-1 position-absolute w-50 end-0 h-100">
            <form className="" onSubmit={onSubmit}>
              <div className="text-center">
                <img src={logo} width={250} alt="" className="mt-3 mb-5 " />
              </div>
              <Input name={"Topic"} containerClass={"mb-3"} required />
              <InputTextArea name={"Message"} required />
              <button
                type="submit"
                className="btn btn-danger rounded-pill text-white my-3"
              >
                Raise Ticket
              </button>
            </form>
          </div>
        </div>

        {/* <div className='bg-light d-flex flex-column rounded-5 h-100 mt-3 flex-grow-1 bg-danger'>
          <h2 className='fw-bold text-danger p-4'>Tickets</h2>

          <div className='bg-white p-4 overflow-y-auto flex-grow-1 '>
            {supportTicketsRes.data.map((item) =>

              <div className='d-flex align-items-center bg-white rounded-4 my-3 shadow'>

                <div className='p-4 pe-5 border-end flex-grow-1'>
                  <h6 className='fw-bold'>Ticket ID</h6>
                  <p className='fs-13 text-muted'>{item.id}</p>
                </div>

                <div className='p-4 pe-5 border-end flex-grow-1'>
                  <h6 className='fw-bold'>Topic</h6>
                  <p className='fs-13 text-muted'>{item.topic}</p>
                </div>

                <div className='p-4 pe-5 border-end flex-grow-1'>
                  <h6 className='fw-bold'>Last Updated</h6>
                  <p className='fs-13 text-muted'>{item.updatedAt && new Date(item.updatedAt).toLocaleDateString()}</p>
                </div>

                <div className='p-4 pe-5 border-end flex-grow-1'>
                  <p className='fs-13 text-white rounded-pill bg-danger text-center py-1 px-3'>Pending</p>
                </div>

                <div className='d-flex p-4'>
                  <div className='btn btn-outline-success border-0 p-0 rounded-circle text-hover-white' onClick={() => setSelectedTicket(item.id)}>{Icons.arrow()}</div>
                </div>

              </div>

            )}
            {supportTicketsRes.data.map((item) =>

              <div className='d-flex align-items-center bg-white rounded-4 my-3 shadow'>

                <div className='p-4 pe-5 border-end flex-grow-1'>
                  <h6 className='fw-bold'>Ticket ID</h6>
                  <p className='fs-13 text-muted'>{item.id}</p>
                </div>

                <div className='p-4 pe-5 border-end flex-grow-1'>
                  <h6 className='fw-bold'>Topic</h6>
                  <p className='fs-13 text-muted'>{item.topic}</p>
                </div>

                <div className='p-4 pe-5 border-end flex-grow-1'>
                  <h6 className='fw-bold'>Last Updated</h6>
                  <p className='fs-13 text-muted'>{item.updatedAt && new Date(item.updatedAt).toLocaleDateString()}</p>
                </div>

                <div className='p-4 pe-5 border-end flex-grow-1'>
                  <p className='fs-13 text-white rounded-pill bg-danger text-center py-1 px-3'>Pending</p>
                </div>

                <div className='d-flex p-4'>
                  <div className='btn btn-outline-success border-0 p-0 rounded-circle text-hover-white' onClick={() => setSelectedTicket(item.id)}>{Icons.arrow()}</div>
                </div>

              </div>

            )}
            {supportTicketsRes.data.map((item) =>

              <div className='d-flex align-items-center bg-white rounded-4 my-3 shadow'>

                <div className='p-4 pe-5 border-end flex-grow-1'>
                  <h6 className='fw-bold'>Ticket ID</h6>
                  <p className='fs-13 text-muted'>{item.id}</p>
                </div>

                <div className='p-4 pe-5 border-end flex-grow-1'>
                  <h6 className='fw-bold'>Topic</h6>
                  <p className='fs-13 text-muted'>{item.topic}</p>
                </div>

                <div className='p-4 pe-5 border-end flex-grow-1'>
                  <h6 className='fw-bold'>Last Updated</h6>
                  <p className='fs-13 text-muted'>{item.updatedAt && new Date(item.updatedAt).toLocaleDateString()}</p>
                </div>

                <div className='p-4 pe-5 border-end flex-grow-1'>
                  <p className='fs-13 text-white rounded-pill bg-danger text-center py-1 px-3'>Pending</p>
                </div>

                <div className='d-flex p-4'>
                  <div className='btn btn-outline-success border-0 p-0 rounded-circle text-hover-white' onClick={() => setSelectedTicket(item.id)}>{Icons.arrow()}</div>
                </div>

              </div>

            )}
          </div>
        </div> */}

        <div className=" hide-scroll flex-grow-1  mt-4 h-100 ">
          <div className="rounded-4 bg-primary mx-3 p-3">
            <h2 className="text-white fw-bold">Tickets</h2>
          </div>

          <div className="overflow-auto container p-0 h-100">
            {supportTicketsRes.data?.map((item) => (
              <div
                className="row row-cols-5 d-flex align-items-center bg-white flex-grow-1 rounded-4 m-3 shadow "
                key={item.id}
              >
                <div className="col-2">
                  <div className="py-3 border-end  ">
                    <div className="fw-bold">Ticket ID</div>
                    <p className="fs-11 text-muted">{item.id}</p>
                  </div>
                </div>

                <div className="col-4">
                  <div className="py-3  border-end ">
                    <h6 className="fw-bold">Topic</h6>
                    <p className="fs-11 text-muted">
                      {item.topic.length > 15
                        ? item.topic.substring(0, 14) + "..."
                        : item.topic}
                    </p>
                  </div>
                </div>

                <div className="col-3">
                  <div className="py-3 border-end">
                    <h6 className="fw-bold">Last Updated</h6>
                    <p className="fs-11 text-muted">
                      {item.updatedAt &&
                        new Date(item.updatedAt).toLocaleDateString()}
                    </p>
                  </div>
                </div>

                <div className="col-2 ">
                  <div className="py-3  border-end">
                    {item.status == "Created" ? (
                      <div
                        className="btn btn-sm  rounded-pill text-white"
                        style={{ backgroundColor: "red" }}
                      >
                        Unresolve
                      </div>
                    ) : (
                      <div
                        className="btn btn-sm  rounded-pill text-white"
                        style={{ backgroundColor: "#508D02" }}
                      >
                        Resolved
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-1">
                  <div className="py-3 text-center">
                    <div
                      className="btn btn-outline-success border-0 p-0 rounded-circle text-hover-white"
                      onClick={() => setSelectedTicket(item.id)}
                    >
                      {Icons.arrow()}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="ms-3 flex-grow-1 h-100  " style={{ width: "40%" }}>
        {selectedTicket ? (
          <SupportAction id={selectedTicket} />
        ) : (
          <div className="w-100 h-100 bg-white d-flex flex-column overflow-hidden rounded-5">
            <div className="fs-3 fw-bold bg-light ps-4 py-1 ">
              <span className="text-danger">Query </span>Tickets
            </div>
            <div className="text-center p-3 flex-grow-1 d-flex justify-content-center align-items-center h-100">
              <div className="w-100">
                <div className=" py-4">
                  {" "}
                  <img src={ticketimg} style={{ width: 250 }} alt="" />
                </div>
                <div className="">
                  Effortlessly resolve your queries by creating query tickets,
                  and we'll assist you in every possible way.
                </div>
                <div className=" fs-13 text-muted py-4">
                  Please be patient as ticket replies may take some time. In
                  urgent cases, connect with the SGRS Utility service provider
                  or Brahmvidhya Vibhag for a swift solution. Your understanding
                  is greatly appreciated.
                </div>
                <div>
                  <img src={bottomimg} alt="" style={{ width: 250 }} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Support;
