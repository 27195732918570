import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCreateBookRatingMutation, useGetBookByIdQuery, useGetBooksQuery, useGetChaptersQuery, useGetUserDetailsQuery } from '../../services/userSlice'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import CreateRatingModal from '../../components/modals/CreateRatingModal'
import SuccessModal from '../../components/modals/SuccessModal'
import Rating from 'react-rating'
import Icons from '../../components/ui/Icons'

const CourseBook = () => {

    const { id } = useParams()
    const response = useGetChaptersQuery(id)
    const resBook = useGetBookByIdQuery(id)
    const userRes = useGetUserDetailsQuery()

    const [submit, submitResponse] = useCreateBookRatingMutation()

    const [selectedTab, setSelectedTab] = useState(0);
    const activeTabClassName = "flex-grow-1 bg-light p-4 pe-5 text-center border-end border-5 border-danger fw-bold text-nowrap";
    const inactiveTabClassName = "flex-grow-1 bg-primary p-4 pe-5 text-center text-white text-nowrap";

    const tabsContainerRef = useRef(null);

    useEffect(() => {
        // Scroll to the selected tab when it changes
        if (tabsContainerRef.current) {
            const selectedTabElement = tabsContainerRef.current.querySelector('.active-tab');
            if (selectedTabElement) {
                selectedTabElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }
    }, [selectedTab]);



    let totalRating = 0;

    resBook?.data?.ratings.forEach((rating) => {
        totalRating += rating.rating;
    });

    const averageRating = totalRating / resBook?.data?.ratings.length;

    if (response.isLoading || userRes.isLoading || resBook.isLoading) return <Loading />
    if (response.isError) return <ApiErrorModal response={response} />

    return (
        <div className='container-fluid py-4 h-100'>
            {
                submitResponse.isSuccess && <SuccessModal label={'Rate'} message={'Rating Submitted'} />
            }
            <div className='mb-3 row px-4 ' >
                <div className='col-9'>
                    <div className="d-flex align-items-center justify-content-center">
                        <h2 className='fw-bold text-danger me-3'>{resBook?.data?.name} <span className='text-black'>| {resBook?.data?.language}</span></h2>
                        <Rating
                            initialRating={averageRating}
                            readonly
                            fullSymbol={Icons.rating('fs-5 text-warning  m-0', {})}
                            emptySymbol={Icons.rating('fs-5  m-0', { color: '#fafafa' })}
                        // onChange={setRating}

                        // style={{width:20}} 

                        />
                    </div>
                    <p className='text-muted'>By: {resBook?.data?.authorName}</p>
                    {/* <p>{averageRating}</p> */}
                </div>

                <div className='col-3'>
                    {(resBook?.data?.ratings.filter((item) => item.userId == userRes.data.id).length <= 0) ? < div className="float-end">  <CreateRatingModal id={id} submit={submit} submitResponse={submitResponse} resBook={resBook} /></div> : < div className="float-end">  <CreateRatingModal id={id} submit={submit} submitResponse={submitResponse} resBook={resBook} reRate /></div>}
                </div>
            </div>
            {
                response.data.length > 0 ?
                    <div className='card hide-scroll rounded-5 overflow-hidden flex-row h-75 flex-grow-1 shadow mx-4'>
                        <div className='tabs-container text-center h-100' ref={tabsContainerRef} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                            {response.data.map((tab, index) => (
                                <div
                                    key={index}
                                    className={selectedTab === index ? activeTabClassName + ' active-tab' : inactiveTabClassName}
                                    onClick={() => setSelectedTab(index)}
                                >
                                    {tab.name}
                                </div>
                            ))}
                        </div>
                        <div className='w-100 p-5 d-flex flex-column justify-content-between'>
                            <p dangerouslySetInnerHTML={{ __html: response.data[selectedTab].text }}></p>
                        </div>
                    </div>
                    :
                    <div>No chapters  in this book</div>
            }



            {/* <Rating /> */}
        </div >
    )
}

export default CourseBook