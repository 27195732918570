import React from 'react'
import bookImg from '../../assets/images/book_poster.png'
import starImg from '../../assets/images/star.png'
import Sections from '../../constants/Sections'
import { Link } from 'react-router-dom'
import { useGetAboutQuery } from '../../services/aboutSlice'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import Slider from '../../components/ui/Slider'

const Sadvidhya = () => {

    const aboutRes = useGetAboutQuery()

    if (aboutRes.isLoading) return <Loading />
    if (aboutRes.isError) return <ApiErrorModal />

    const filteredData = aboutRes.data.filter(item => item.type === "Sadvidhya")

    return (
        <div className='py-5 d-flex h-100'>

            <div className='px-5 w-75'>
                <Slider type="ICGS-Sadvidhya" />
                <h2 className='fw-bold text-black my-4'>Explore books for <span className='text-danger'>Sadvidhya</span></h2>
                {
                    Sections.SECTIONS_DATA.filter(i => i.name === "Sadvidhya").map(item =>
                        <div className='row row-cols-3 g-3 mb-5'>
                            {item.books.map(item2 =>
                                <Link className="col text-decoration-none " to={'' + item2.name}>
                                    <div className='d-flex p-3 bg-light overflow-hidden rounded-4 border border-2 border-white' >
                                    <img src={item2.url} alt="" className='me-2' style={{width:150}} />
                                        <div className='d-flex flex-column justify-content-center'>
                                            <h6 className='fw-bold mb-2'>{item2.name}</h6>
                                            <p className='text-muted lh-sm mb-2'>item.language</p>
                                            <p className='text-muted lh-sm mb-2'>item.authorName</p>
                                            <p className='text-muted lh-sm mb-2'>item.category?.name</p>
                                            <img src={starImg} alt="" className='w-50' />
                                        </div>
                                    </div>
                                </Link>
                            )}
                        </div>
                    )
                }
            </div>

            <div className='bg-white rounded-start-5 shadow overflow-hidden w-25'>
                <div className='d-flex align-items-center justify-content-between p-4' style={{ backgroundColor: "#F5F5F5" }}>
                    <h5 className='text-danger fs-4  fw-bold me-3'>About Sadvidhya</h5>
                </div>
                {filteredData.length > 0 &&
                    <div className='p-3' style={{ wordWrap: 'break-word' }}
                        dangerouslySetInnerHTML={{ __html: filteredData[0].content }}></div>
                }
            </div>

        </div>
    )
}

export default Sadvidhya