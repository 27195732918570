import { Route, Routes, } from 'react-router-dom';
import { useEffect, useState } from 'react';


import "./assets/scss/bootstrap.scss"
import "./assets/css/global.css"
import "./assets/css/fonts.css"
import "./assets/css/scrollbar.css"
import "./assets/css/settings.css"

import Layout from './components/layout/Layout';
import Dashboard from './pages/Dashboard';
import SandhyaSabha from './pages/sandhyaSabha/SandhyaSabha';
import Assignments from './pages/assignments/Assignments';
import CourseBooks from './pages/courseBooks/CourseBooks';
import Training from './pages/training/Training';
import Info from './pages/info/Info';
import Downloadables from './pages/downloadables/Downloadables';
import Support from './pages/support/Support';
import Sessions from './pages/training/Sessions';
import CourseBook from './pages/courseBooks/CourseBook';
import { useSelector } from 'react-redux';
import SupportAction from './pages/support/SupportAction';
import Tutorial from './pages/sandhyaSabha/Tutorial';
import Assignment from './pages/assignments/Assignment';
import Settings from './pages/setting/Settings';
import CourseBooksPerCategory from './pages/courseBooks/CourseBooksPerCategory';
import IcgsPinwheel from './pages/icgsPinwheel/IcgsPinwheel';
import ViewBook from './pages/icgsPinwheel/ViewBook';
import ViewSection from './pages/icgsPinwheel/ViewSection';
import TutorialAssignment from './pages/assignments/TutorialAssignment';
import Notices from './pages/notice/Notices';
import SettingsV1 from './pages/setting/SettingsV1';
import PinWheel2 from './pages/icgsPinwheel/PinWheel2';
import SignIn from './pages/signIn/SignIn';
import SignInByToken from './pages/signIn/SignInByToken';
import SignInHandler from './pages/signIn/SignInHandler';
import SingleSignOn from './pages/signIn/SingleSignOn';

function App() {

  // const [signIn, setSignIn] = useState(false)
  // const isSignedIn = useSelector(state => state.apiSlice.mutations?.signIn?.data?.token);
  const isSignedIn = useSelector(state => state.auth.authToken);

  useEffect(() => { import('bootstrap') }, [])

  return (

    <Routes>

      {
        isSignedIn
          ?
          <Route path='/' element={<Layout />}>

            <Route index element={<Dashboard />} />
            <Route path='singleSignOn' element={<SingleSignOn />} />
            <Route path='token/:token' element={<SignInByToken />} />

            <Route path='sandhyaSabha'>
              <Route index element={<SandhyaSabha />} />
              <Route path='tutorial/:id' element={<Tutorial />} />
            </Route>

            <Route path='assignments'>
              <Route index element={<Assignments />} />
              <Route path=':id' element={<Assignment />} />
              <Route path='tutorial/:id' element={<TutorialAssignment />} />
            </Route>

            {/* <Route path='coursebooks' element={<CourseBooks />} /> */}
            <Route path='coursebooks'>
              <Route index element={<CourseBooks />} />
              <Route path=':id' index element={<CourseBook />} />
              <Route path='category/:id' index element={<CourseBooksPerCategory />} />
            </Route>

            <Route path='downloadables' element={<Downloadables />} />

            <Route path='training'>
              <Route index element={<Training />} />
              <Route path=':id' index element={<Sessions />} />
            </Route>

            <Route path='support' >
              <Route index element={<Support />} />
            </Route>

            <Route path='settingV1' element={<SettingsV1 />} />
            <Route path='settings' element={<Settings />} />

            <Route path='info' element={<Info />} />


            <Route path='notice' >
              <Route index element={<Notices />} />
            </Route>

            <Route path='icgsPinwheel'>
              <Route index element={<PinWheel2 />} />
              <Route index element={<IcgsPinwheel />} />
              <Route path=':section/:book' element={<ViewBook />} />
              <Route path=':section'>
                <Route index element={<ViewSection />} />
                <Route path=':book' element={<ViewBook />} />
              </Route>
              {/* <Route path='vidhya'>
                <Route index element={<Vidhya />} />
                <Route path=':id' element={<ViewBook />} />
              </Route>
              <Route path='sadvidhya'>
                <Route index element={<Brahmvidhya />} />
                <Route path=':id' element={<ViewBook />} />
              </Route>
              <Route path='brahmvidhya'>
                <Route index element={<Charitram />} />
                <Route path=':id' element={<ViewBook />} />
              </Route>
              <Route path='charitram'>
                <Route index element={<Vidhya />} />
                <Route path=':id' element={<ViewBook />} />
              </Route> */}
            </Route>

            <Route path='*' element={<Dashboard />} />

          </Route>
          :
          <Route path='/'>
            <Route index element={<SignIn />} />
            <Route path='token/:token' element={<SignInByToken />} />
            <Route path='*' element={<SignInHandler />} />
          </Route>
      }

    </Routes>


  );

}

export default App;