import React from "react";
import { useGetUserDetailsQuery } from "../../services/userSlice";
import { Link, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAuthToken } from "../../redux/authSlice";
import Loading from "../ui/Loading";
import ApiErrorModal from "../modals/ApiErrorModal";
import Api from "../../constants/Api";
import Image from "../ui/Image";
import Icons from "../ui/Icons";

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const res = useGetUserDetailsQuery();

  const handleSignOut = () => dispatch(setAuthToken(null));

  if (res.isLoading) return <Loading />;
  if (res.isError) return <ApiErrorModal response={res} />;

  return (
    <div className="dropdown ">
      <button
        className="btn bg-white rounded-pill px-3 d-flex  align-items-center shadow dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {
       
          <Image
            src={
              Api.SUPERADMIN_URL +
              `uploads/images/profilePictures/${res.data.profilePicture?.id}${res.data.profilePicture?.ext}`
            }
            alt=""
            className="me-3 rounded-circle"
            style={{ width: 30, height: 30 }}
            icon={() =>
              Icons.defaultUser("", { width: "100%", height: "100%" })
            }
          />
        }
        {res.data.name}
      </button>
      <ul className="dropdown-menu">
        <li>
          <NavLink className="dropdown-item" to={"/settingV1"}>
            Settings
          </NavLink>
        </li>
        {res.data.educationPortalAccess != null && (
          <li>
            <Link
              className="dropdown-item"
              to={`http://62.72.31.126:3007/token/${res.data.token}`}
              target="_blank"
            >
              Go to Education Portal
            </Link>
          </li>
        )}

        <li>
          <button className="dropdown-item" onClick={handleSignOut}>
            Sign Out
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ProfileDropdown;
