import React from 'react';

const CircularProgressBar = ({ progress }) => {
  const radius = 30; // Set the radius of the bigger circle
  const circumference = 2 * Math.PI * radius;
  const progressPercentage = (progress / 100) * circumference;

  const textX = 50; // X position for the text
  const textY = 50; // Y position for the text

  return (
    <svg height="90" width="90" className=''> {/* Adjusted height and width for the bigger circle */}
      <circle
        className="progress-ring-circle"
        stroke="#8888" // Set the color of the progress bar
        strokeWidth="13" // Adjusted thickness for the bigger circle
        fill="transparent"
        r={radius}
        cx="50" // Adjusted center position for the bigger circle
        cy="50" // Adjusted center position for the bigger circle
      />
      <circle
        className="progress-ring-indicator"
        stroke="#0773FD" // Set the color of the progress indicator
        strokeWidth="13" // Adjusted thickness for the bigger circle
        fill="transparent"
        r={radius}
        cx="50" // Adjusted center position for the bigger circle
        cy="50" // Adjusted center position for the bigger circle
        style={{
          strokeDasharray: `${circumference} ${circumference}`,
          strokeDashoffset: circumference - progressPercentage,
        }}
      />
      <text x={textX} y={textY} textAnchor="middle" dy=".3em" fontSize="20" fill="#000">{ Math.round(progress)}%</text>
    </svg>
  );
};

export default CircularProgressBar;
