import React from "react";
import congratsPoster from "../../assets/images/congrats_poster.png";
import poster from "../../assets/images/assignment_poster.png";
import Icons from "../../components/ui/Icons";
import {
  useGetAssignmentsQuery,
  useGetTutorialsQuery,
} from "../../services/userSlice";
import Loading from "../../components/ui/Loading";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import userImg2 from "../../assets/images/user2.png";
import { Link } from "react-router-dom";
import Api from "../../constants/Api";
import Slider from "../../components/ui/Slider";
import logoGif from "../../assets/gif/logo.gif";

const Assignments = () => {
  const response = useGetAssignmentsQuery();

  const getTutorial = useGetTutorialsQuery();

  if (response.isLoading) return <Loading />;
  if (response.isError) return <ApiErrorModal response={response} />;

  return (
    <div className="container-fluid py-4 d-flex h-100 flex-wrap">
      <div className="col-lg-9 px-2 overflow-hidden hide-scroll">
        <div className="overflow-auto h-100 w-100 ">
          <div className="row px-4">
            <Slider type="Assignments" />
          </div>

          <h2 className="fw-bold text-danger mt-3 mb-1 px-4">Assignments</h2>
          <p className="mb-4 px-4">
            This page offers a quick view of all your assignments, making it
            easy to stay organized and meet deadlines.
          </p>
          <div className="row row-cols-2 g-3 px-4 bg-dange">
            {/* Your code here */}

            {response.data
              .filter((i) => i.status == "Active")
              .map((item) => (
                <div key={item.id} className="col">
                  <div className="card shadow rounded-4 border-0  p-3 flex-row justify-content-between">
                    <div>
                      <p className="fw-bold">{item.assignmentName}</p>
                      <p className="fs-13 text-muted">{item.subtitle}</p>
                    </div>

                    <Link
                      className="btn btn-outline-success border-0 p-0 rounded-circle text-hover-white"
                      to={"" + item.id}
                    >
                      {Icons.arrow()}
                    </Link>
                  </div>
                </div>
              ))}

            {getTutorial?.data
              ?.filter((item) => item.acceptingAssignments)
              .map((item) => (
                <div key={item.id} className="col">
                  <div className="card shadow rounded-4 border-0  p-3 flex-row justify-content-between">
                    <div>
                      {/* <p className='fw-bold'dangerouslySetInnerHTML={{ __html: item?.pageTitle?.length > 40 ? item.pageTitle.substring(0, 39) + "...": item.pageTitle }}></p> */}
                      {/* <p className='fw-bold'>{new DOMParser().parseFromString(item?.pageTitle, 'text/html').body}</p> */}
                      <p className="fw-bold">
                        {(
                          (
                            new DOMParser().parseFromString(
                              item?.pageTitle || "",
                              "text/html"
                            ).body.textContent || ""
                          )
                            .replace(/(\r\n|\n|\r)/gm, " ")
                            .substring(0, 50) +
                          (item?.pageTitle?.length > 50 ? "..." : "")
                        ).trim()}
                      </p>

                      <p className="fs-13 text-muted">{`${item.section} > ${item.books} > ${item.topic}`}</p>
                    </div>

                    <Link
                      className="btn btn-outline-success border-0 p-0 rounded-circle text-hover-white"
                      to={"tutorial/" + item.id}
                    >
                      {Icons.arrow()}
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="col-lg-3 p-3 bg-white rounded-start-5 d-flex align-items-center">
        <img src={logoGif} alt="" className="w-100" />
      </div>
    </div>
  );

  return (
    <div className="container-fluid py-4 d-flex h-100 ">
      {/* {
        console.log("e", getTutorial.data)
      } */}
      <div className="w-75  me-3 overflow-hidden hide-scroll">
        <div className="overflow-auto h-100 w-100 ">
          <div className="row px-4">
            <Slider type="Assignments" />
          </div>

          <h2 className="fw-bold text-danger mt-3 mb-1 px-4">Assignments</h2>
          <p className="mb-4 px-4">
            This page offers a quick view of all your assignments, making it
            easy to stay organized and meet deadlines.
          </p>
          <div className="row row-cols-2 g-3 px-4 bg-dange">
            {response.data
              .filter((i) => i.status == "Active")
              .map((item) => (
                <div key={item.id} className="col">
                  <div className="card shadow rounded-4 border-0  p-3 flex-row justify-content-between">
                    <div>
                      <p className="fw-bold">{item.assignmentName}</p>
                      <p className="fs-13 text-muted">{item.subtitle}</p>
                    </div>

                    <Link
                      className="btn btn-outline-success border-0 p-0 rounded-circle text-hover-white"
                      to={"" + item.id}
                    >
                      {Icons.arrow()}
                    </Link>
                  </div>
                </div>
              ))}

            {getTutorial?.data
              ?.filter((item) => item.acceptingAssignments)
              .map((item) => (
                <div key={item.id} className="col">
                  <div className="card shadow rounded-4 border-0  p-3 flex-row justify-content-between">
                    <div>
                      {/* <p className='fw-bold'dangerouslySetInnerHTML={{ __html: item?.pageTitle?.length > 40 ? item.pageTitle.substring(0, 39) + "...": item.pageTitle }}></p> */}
                      {/* <p className='fw-bold'>{new DOMParser().parseFromString(item?.pageTitle, 'text/html').body}</p> */}
                      <p className="fw-bold">
                        {(
                          (
                            new DOMParser().parseFromString(
                              item?.pageTitle || "",
                              "text/html"
                            ).body.textContent || ""
                          )
                            .replace(/(\r\n|\n|\r)/gm, " ")
                            .substring(0, 50) +
                          (item?.pageTitle?.length > 50 ? "..." : "")
                        ).trim()}
                      </p>

                      <p className="fs-13 text-muted">{`${item.section} > ${item.books} > ${item.topic}`}</p>
                    </div>

                    <Link
                      className="btn btn-outline-success border-0 p-0 rounded-circle text-hover-white"
                      to={"tutorial/" + item.id}
                    >
                      {Icons.arrow()}
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="w-25 p-3 bg-white rounded-start-5 d-flex align-items-center">
        <img src={logoGif} alt="" className="w-100" />

        {/* <img src={congratsPoster} alt="" className='w-100' />

        <div className='p-3 bg-warning rounded-4 flex-grow-1 ms-3'>

          <h4 className='fw-bold mb-3'>LEADERBOARD</h4>
          <div className='d-flex bg-white p-2 rounded-3 align-items-center mb-3'>
            <img src={userImg2} alt="" />
            <div className='mx-3'>
              <h6 className='fw-bold'>Hari Kumar</h6>
              <p>2334 credits</p>
            </div>
          </div>

          <div className='d-flex bg-white p-2 rounded-3 align-items-center mb-3'>
            <img src={userImg2} alt="" />
            <div className='mx-3'>
              <h6 className='fw-bold'>Hari Kumar</h6>
              <p>2334 credits</p>
            </div>
          </div>

          <div className='d-flex bg-white p-2 rounded-3 align-items-center'>
            <img src={userImg2} alt="" />
            <div className='mx-3'>
              <h6 className='fw-bold'>Hari Kumar</h6>
              <p>2334 credits</p>
            </div>
          </div>

        </div> */}
      </div>
    </div>
  );
};

export default Assignments;
