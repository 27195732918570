import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserDetails: builder.query({ query: () => ``, keepUnusedDataFor: 0 }),
        getAssignments: builder.query({ query: () => `assignment`, keepUnusedDataFor: 0 }),
        getAssignmentById: builder.query({ query: (id) => `assignment?id=${id}`, keepUnusedDataFor: 0 }),
        getBooks: builder.query({ query: () => `book`, keepUnusedDataFor: 0 }),
        getBookById: builder.query({ query: (id) => `book?id=${id}`, keepUnusedDataFor: 0 }),
        getCategories: builder.query({ query: () => `category`, keepUnusedDataFor: 0 }),
        getChapters: builder.query({ query: (bookId) => `chapters?bookId=${bookId}`, keepUnusedDataFor: 0 }),
        getModules: builder.query({ query: () => `trainingModule`, keepUnusedDataFor: 0 }),
        getModuleById: builder.query({ query: (id) => `trainingModule?id=${id}`, keepUnusedDataFor: 0 }),





        getTutorials: builder.query({ query: () => `tutorial`, keepUnusedDataFor: 0 }),
        getTutorialById: builder.query({ query: (id) => `tutorial?id=${id}`, keepUnusedDataFor: 0 }),
        getInfos: builder.query({ query: () => `info`, keepUnusedDataFor: 0 }),
        getInfoById: builder.query({ query: (id) => `info?id=${id}`, keepUnusedDataFor: 0 }),
        getNotices: builder.query({ query: () => `notice`, keepUnusedDataFor: 0 }),
        getNoticeById: builder.query({ query: (id) => `notice?id=${id}`, keepUnusedDataFor: 0 }),
        getTerms: builder.query({ query: () => `termsAndConditions`, keepUnusedDataFor: 0 }),

        createBookRating: builder.mutation({ query: ({ id, body }) => ({ url: `book/rating?bookId=${id}`, method: 'POST', body }) }),


        createSubmitAssignment: builder.mutation({ query: ({ id, body }) => ({ url: `assignment/submit?assignmentId=${id}`, method: 'POST', body }) }),
        createSubmitTutorial: builder.mutation({ query: ({ id, body }) => ({ url: `tutorial/submit?tutorialId=${id}`, method: 'POST', body }) }),
        createSuggestion: builder.mutation({ query: ({ id, body }) => ({ url: `tutorial/suggestion?id=${id}`, method: 'POST', body }) }),

        getTechSupport: builder.query({ query: () => `technicalSupport`, keepUnusedDataFor: 0 }),
        getPolicy: builder.query({ query: () => `policySetting`, keepUnusedDataFor: 0 }),
        getCmsSupport: builder.query({ query: () => `cmsSupport`, keepUnusedDataFor: 0 }),

        markTutotrialRead: builder.mutation({ query: (body) => ({ url: `mark-tutorial-read`, method: 'POST', body }) }),
        markTutotrialUnRead: builder.mutation({ query: (body) => ({ url: `mark-tutorial-unread`, method: 'POST', body }) }),


        patchSubmitAssignment: builder.mutation({ query: ({ body, id }) => ({ url: `assignment/submit?id=${id}`, method: 'PATCH', body }) }),
        patchSubmitTutorial: builder.mutation({ query: ({ body, id }) => ({ url: `tutorial/submit?id=${id}`, method: 'PATCH', body }) }),

    })
})

export const {
    useGetUserDetailsQuery,
    useGetPolicyQuery,
    useGetAssignmentsQuery,
    useGetAssignmentByIdQuery,
    useGetBooksQuery,
    useGetCategoriesQuery,
    useGetChaptersQuery,
    useGetModulesQuery,
    useGetModuleByIdQuery,
    useGetTutorialsQuery,
    useGetTutorialByIdQuery,
    useGetInfosQuery,
    useGetInfoByIdQuery,
    useGetNoticesQuery,
    useGetNoticeByIdQuery,
    useCreateSubmitAssignmentMutation,
    useGetTermsQuery,
    useCreateSuggestionMutation,
    useCreateBookRatingMutation,
    useGetBookByIdQuery,
    useGetTechSupportQuery,
    useCreateSubmitTutorialMutation,
    useMarkTutotrialReadMutation,
    useMarkTutotrialUnReadMutation,
    usePatchSubmitAssignmentMutation,
    usePatchSubmitTutorialMutation,
    useGetCmsSupportQuery,
} = extendedApiSlice
