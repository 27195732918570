// import React from 'react';

// function SessionReadButton({ module, selectedSessionTab, getUser, handleSessionRead }) {
//   // Check if markAsReads is available and filter it based on userId
//   const markAsRead = module.sessions[selectedSessionTab]?.markAsReads.find(item => item.userId === getUser.data.id);

//   const isChecked = markAsRead ? markAsRead.isRead : false;



//   return (
  

//     <div>
//       <label className="checkbox-container mb-3 me-2">
//         <input
//         className=''
//           type="checkbox"
//           checked={isChecked}
//           onChange={() => handleSessionRead(module.sessions[selectedSessionTab]?.id)}
//         />
//         <span className="checkmark"></span>
//       </label>
//     </div>
//   );
// }

// export default SessionReadButton;



// import React from 'react';

// function SessionReadCheckbox({ isButton, module, selectedSessionTab, getUser, handleSessionRead }) {
//   // Check if markAsReads is available and filter it based on userId
//   const markAsRead = module.sessions[selectedSessionTab]?.markAsReads.find(item => item.userId === getUser.data.id);

//   // Determine whether the checkbox is checked based on the presence of markAsRead and its isRead property
//   const isChecked = markAsRead ? markAsRead.isRead : false;

//   return (
//     <div>
//       <div className="form-check">
//         <input
//           className="form-check-input ms-2"
//           type="checkbox"
//           checked={isChecked}
//           onChange={() => handleSessionRead(module.sessions[selectedSessionTab]?.id)}
//         />
//       </div>
//     </div>
//   );
// }

// export default SessionReadCheckbox;



function SessionReadCheckbox({ isButton, module, selectedSessionTab, getUser, handleSessionRead }) {
  // Check if markAsReads is available and filter it based on userId
  const markAsRead = module.sessions[selectedSessionTab]?.markAsReads.find(item => item.userId === getUser.data.id);

  // Determine whether the checkbox is checked based on the presence of markAsRead and its isRead property
  const isChecked = markAsRead ? markAsRead.isRead : false;

  return (
    <div>
      {isButton ? (
        <button
          className= {isChecked ? "btn btn-danger rounded-pill mb-10" : "btn btn-success rounded-pill mb-1"}
          onClick={() => handleSessionRead(module.sessions[selectedSessionTab]?.id)}
        >
          {isChecked ? "Mark as Unread" : "Mark as Read"}
        </button>
      ) : (
        <div className="form-check">
          <input
            className="form-check-input ms-2"
            type="checkbox"
            checked={isChecked}
            onChange={() => handleSessionRead(module.sessions[selectedSessionTab]?.id)}
          />
        </div>
      )}
    </div>
  );
}

export default SessionReadCheckbox;
