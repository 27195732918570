import React, { useEffect, useRef, useState } from 'react'
import poster from '../../assets/images/training_poster.png'
import progressImg from '../../assets/images/progress.png'
import { useGetModulesQuery } from '../../services/userSlice';
import { Link } from 'react-router-dom';
import Loading from '../../components/ui/Loading';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import TrainingCompletion from './TrainingCompletion';
import ModuleDoneLabel from '../../components/ui/ModuleDoneLabel';

const Training = () => {

  const [selectedTab, setSelectedTab] = useState(null);
  const activeTabClassName = "flex-grow-1 bg-light p-4 pe-5 text-center border-end border-5 border-danger fw-bold text-nowrap";
  const inactiveTabClassName = "flex-grow-1 bg-primary p-4 pe-5 text-center text-white text-nowrap";

  const response = useGetModulesQuery();
  let totalSessions = 0;
  let completedSessions = 0;

  const tabsContainerRef = useRef(null);

  useEffect(() => {
    // Scroll to the selected tab when it changes
    if (tabsContainerRef.current) {
      const selectedTabElement = tabsContainerRef.current.querySelector('.active-tab');
      if (selectedTabElement) {
        selectedTabElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  }, [selectedTab]);

  useEffect(() => {
    if (response.isSuccess) {
      setSelectedTab(response.data.length > 0 ? 0 : null)
    }
  }, [response.isSuccess, response.data])

  useEffect(() => {
    if (response.isFetching) {
      setSelectedTab(null)
    }
  }, [response.isFetching])


  if (response.isLoading) return <Loading />
  if (response.isError) return <ApiErrorModal response={response} />


  response.data.forEach(module => {
    // Iterate through sessions in each module
    module.sessions.forEach(session => {
      // Increment total sessions count
      totalSessions++;

      // Check if session has markAsReads and any of them is marked as read
      if (session.markAsReads && session.markAsReads.some(markAsRead => markAsRead.isRead)) {
        completedSessions++;
      }
    });
  });






  return (
    <div className='container-fluid p-5  pe-3 h-100 d-flex justify-content-between'>

      <div className='w-75 d-flex flex-column'>

        <div>
          <h2 className='fw-bold text-danger mb-3'>Training Department</h2>
          <p className='text-muted'>Welcome to our training section, the perfect hub for honing your teaching skills—where you'll discover the art and science of
            effective education! Let's Step into the ideal space for learning 'how to teach'!</p>
        </div>

        <div className='d-flex h-75 w-100 mt-4 flex-grow-1'>

          <div className='card rounded-5 overflow-hidden hide-scroll flex-row shadow  w-100'>
            <div className='tabs-container  text-center ' ref={tabsContainerRef} style={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
              {response.data.map((tab, index) => (
                <div
                  key={index}
                  className={selectedTab === index ? activeTabClassName + ' active-tab' : inactiveTabClassName}
                  onClick={() => setSelectedTab(index)}
                >
                  {tab.name}
                </div>
              ))}
            </div>

            {
              typeof selectedTab === 'number' &&
              <div className='w-100 p-5 d-flex flex-column justify-content-between'>
                <div className="d-flex justify-content-between">
                  <h3 className='fw-bold mb-3'><span className='text-danger'>Overview </span>of the Module</h3>
                  <ModuleDoneLabel module={response.data[selectedTab]} />
                </div>
                <p className='h-100' dangerouslySetInnerHTML={{ __html: response.data[selectedTab].overview }}></p>

                <div className='text-end'>
                  <Link className="btn btn-primary rounded-pill text-white px-5 text-nowrap" to={"/training/" + response.data[selectedTab].id}>Start Learning</Link>
                </div>
              </div>
            }

          </div>


        </div>

      </div>

      <TrainingCompletion totalSessions={totalSessions} completedSessions={completedSessions} />
    </div>
  )
}

export default Training
