import React from 'react'
import poster from '../../assets/images/info_poster.png'
import poster2 from '../../assets/images/info2.png'
import { useGetInfosQuery } from '../../services/userSlice';
import Loading from '../../components/ui/Loading';
import ApiErrorModal from '../../components/modals/ApiErrorModal';
import Api from '../../constants/Api';
import Slider from '../../components/ui/Slider';
import { Link } from 'react-router-dom';

const Info = () => {

  const response = useGetInfosQuery();

  if (response.isLoading) return <Loading />
  if (response.isError) return <ApiErrorModal response={response} />

  return (
    <div className='container-fluid p-5 '>
      <Slider type="Info" />

      {/* <div className='d-flex justify-content-between align-items-center mb-3'>
        <h3 className='fw-bold mb-3 text-danger'>About ICGS</h3>
      </div> */}

      {response.data.map(item =>
        <div className='card border-0 rounded-5 shadow p-4 my-3'>
          <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
        </div>
      )}

    </div>
  )
}

export default Info
