import React, { useEffect, useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react';

const FormattedInput = ({ defaultValue, name, disabled, label }) => {
    const editorRef = useRef(null);
    const id = name || "formattedInput"

    useEffect(() => {
        const handler = (e) => {
            if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
                e.stopImmediatePropagation();
            }
        };
        document.addEventListener("focusin", handler);
        return () => document.removeEventListener("focusin", handler);
    }, []);

    return (
        <>
            {name && <div className='' htmlFor={name}>{label || name}:</div>}
            <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => editorRef.current = editor}
                textareaName={id}
                id={id}
                initialValue={defaultValue || ""}
                disabled={disabled || false}
                init={{
                    height: 500,
                    // menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />

        </>
    )
}

export default FormattedInput