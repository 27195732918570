import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Input from '../../components/form/Input'
import Icons from '../../components/ui/Icons'
import icgsLogo from '../../assets/images/icgs_logo.png'
import pinWheel1img from '../../assets/images/pinWheel1.png'
import pinWheel2img from '../../assets/images/pinWheel2.png'
import pinWheel3img from '../../assets/images/pinWheel3.png'
import { useGetAboutQuery } from '../../services/aboutSlice'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import Loading from '../../components/ui/Loading'

const IcgsPinwheel = () => {

    const containerClass = "col mb-4"
    const [selectedTab, setSelectedTab] = useState("Primary Details")
    const activeTabClassName = "border-start border-5 border-primary p-3 fw-bold";
    const inactiveTabClassName = "p-3 fw-bold";


    const aboutRes = useGetAboutQuery()

    if (aboutRes.isLoading) return <Loading />
    if (aboutRes.isError) return <ApiErrorModal />

    const filteredData = aboutRes.data.filter(item => item.type === "Pinwheel")

    const VidhyaFilteredData = aboutRes.data.filter(item => item.type === "Vidhya")
    const sadvidhyaFilteredData = aboutRes.data.filter(item => item.type === "Sadvidhya")
    const brahmvidhyaFilteredData = aboutRes.data.filter(item => item.type === "Brahmvidhya")
    const charitramFilteredData = aboutRes.data.filter(item => item.type === "Charitram")


    

    return (
        <div className='p-5 container-fluid  d-flex h-100 '>

      

        <div className='' style={{width:'30%',marginTop:130}}>

            <div className='bg-white w-100    d-flex flex-column rounded-5 align-items-center shadow overflow-hidden ' style={{ zIndex: 50, }}>
                <img src={pinWheel2img} style={{ width: 300, marginTop: -10 }} alt="" />
                <img src={pinWheel1img} style={{ width: 300 }} alt="" />
                <img src={pinWheel3img} style={{ width: 300 }} alt="" />

                <Link to={'vidhya'} className='btn btn-info fs-3  fw-bold w-100 m-0 ' style={{ color: '#86FFFA', border: '2px solid #86FFFA', }}>The ICGS Pin Wheel</Link>

            </div>


        </div>


        <div className='' style={{width:'70%'}}>

            <div className='container mb-3   d-flex  h-50 gap-3'>
                <div className='card w-100 h-100 mb-3  rounded-top-5'>
                    <Link to={'vidhya'} className='btn text-white fs-5 fw-bold py-3 rounded-pill' style={{ backgroundColor: '#0065AD' }}>Vidhya</Link>
                    <div className='overflow-hidden m-2 ' style={{ height: 250 }} dangerouslySetInnerHTML={{ __html: VidhyaFilteredData[0].content }} />
                </div>
                <div className='card w-100 h-100 mb-3  rounded-top-5'>
                    <Link to={'sadvidhya'} className='btn text-white fs-5 fw-bold py-3 rounded-pill' style={{ backgroundColor: '#E5AD00' }}>Sadhvidya</Link>
                    <div className='overflow-hidden m-2 ' style={{ height: 250 }} dangerouslySetInnerHTML={{ __html: sadvidhyaFilteredData[0].content }} />
                </div>

            </div>

            <div className='container mb-4 d-flex  h-50 gap-2'>
                <div className='card w-100 h-100 mb-3  rounded-top-5 '>
                    <Link to={'brahmvidhya'} className='btn text-white fs-5 fw-bold py-3 rounded-pill' style={{ backgroundColor: '#E52EB7' }}>Brahmvidya</Link>

                    <div className='overflow-hidden m-2 ' style={{ height: 250 }} dangerouslySetInnerHTML={{ __html: brahmvidhyaFilteredData[0].content }} />
                </div>
                <div className='card w-100 h-100 mb-3  rounded-top-5'>
                    <Link to={'charitram'} className='btn text-white fs-5 fw-bold py-3 rounded-pill' style={{ backgroundColor: '#659B40' }}>Charitram</Link>

                    <div className='overflow-hidden m-2 ' style={{ height: 250 }} dangerouslySetInnerHTML={{ __html: charitramFilteredData[0].content }} />
                </div>

            </div>
        </div>



    </div>
    )
}

export default IcgsPinwheel