import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSessions: builder.query({ query: (moduleId) => `session?trainingModuleId=${moduleId}`, keepUnusedDataFor: 0 }),
        createSessionRating: builder.mutation({ query: ({ id, body }) => ({ url: `session/rating?id=${id}`, method: 'POST', body }) }),

        toggleSessionRead: builder.mutation({ query: (id) => ({ url: `toggle-session-read?sessionId=${id}`, method: 'POST' }) }),
    })
})

export const {

    useGetSessionsQuery,
    useCreateSessionRatingMutation,
    useToggleSessionReadMutation,
} = extendedApiSlice
