import React from 'react'
import { useGetCmsSupportQuery, useGetPolicyQuery, useGetTechSupportQuery, useGetTermsQuery, useGetUserDetailsQuery } from '../../services/userSlice'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import Api from '../../constants/Api'
import Icons from '../../components/ui/Icons'
import Image from '../../components/ui/Image'

const SettingsV1 = () => {

    const response = useGetUserDetailsQuery()
    console.log(response.data)
    const getTerms = useGetTermsQuery()
    const getTech = useGetTechSupportQuery()
    const getPolicy = useGetPolicyQuery()
    const getCms = useGetCmsSupportQuery()

    if (response.isLoading || getTerms.isLoading || getTech.isLoading || getPolicy.isLoading) return <Loading />
    if (response.isError) return <ApiErrorModal response={response} />
    if (getTerms.isError) return <ApiErrorModal response={getTerms} />

    const user = response.data

    console.log(user.profilePicture)
    console.log(Api.PROFILE_PICTURE_URL + user.branch.profilePicture.id + user.branch.profilePicture.ext)

    return (
        <div className="h-100 p-4" style={{ zIndex: 5 }}>
            <section className="section profile bg-white rounded-5 h-100 overflow-auto">
                <div className="row">
                    <div className="col-xl-4">
                        <div className="card  border-0" >
                            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center" >
                                <div className="w-100">
                                    {
                                        user.branch.profilePicture ?


                                            <Image
                                                src={Api.PROFILE_PICTURE_URL + user?.branch?.profilePicture?.id + user?.branch?.profilePicture?.ext}
                                                alt="profile"
                                                className="w-100 rounded-5"
                                                style={{ height: 200, marginBottom: -50, }}
                                                icon={() => Icons.defaultUser("", { width: "100%", height: "100%" })}
                                            />
                                            :
                                            <img alt="Profile" className="w-100 rounded-5"
                                                src={Api.PROFILE_PICTURE_URL + user?.profilePicture?.id + user?.profilePicture?.ext} style={{ height: 150, marginBottom: -50, }} />

                                    }

                                </div>

                                <Image
                                    src={
                                        Api.SUPERADMIN_URL +
                                        `uploads/images/profilePictures/${user.profilePicture.id}${user.profilePicture.ext}`
                                    }
                                    // src={
                                    //     Api.SUPERADMIN_URL +
                                    //     Api.PROFILE_PICTURE_URL + user.profilePicture.id + user.profilePicture.ext
                                    // }
                                    alt="profile"
                                    className="rounded-circle fs-6 bg-white border border-5 border-white"
                                    style={{ height: 80, width: 80 }}
                                    icon={() => Icons.defaultUser("", { width: "100%", height: "100%" })}
                                />

                                <h2>{response.data.name}</h2>
                                <h3>{response.data.post}</h3>
                            </div>

                            <div className="card border-0">
                                <hr className="mx-4" />
                                <div className="px-4">
                                    <div className='d-flex align-items-center mb-4'>
                                        <div className='fw-bold'>{Icons.gear('fs-2')}</div>
                                        <div className='ms-3'>for technical support</div>
                                    </div>
                                    <div className="fw-bold">
                                        Call Us
                                    </div>
                                    <div className="text-muted mb-4">
                                        +91-{getTech?.data?.length > 0 ? getTech?.data[0]?.phone : ''}
                                    </div>
                                    <div className="fw-bold">
                                        Email Us
                                    </div>
                                    <div className="text-muted mb-4">
                                        {getTech?.data?.length > 0 ? getTech?.data[0]?.email : ''}
                                    </div>
                                </div>

                                <div className="px-4">
                                    <div className='d-flex align-items-center mb-4'>
                                        <div className='fw-bold'>{Icons.gear('fs-2')}</div>
                                        <div className='ms-3'>for CMS support</div>
                                    </div>
                                    <div className="fw-bold">
                                        Call Us
                                    </div>
                                    <div className="text-muted mb-4">
                                        +91-{getCms?.data?.length > 0 ? getCms?.data[0]?.phone : ''}
                                    </div>
                                    <div className="fw-bold">
                                        Email Us
                                    </div>
                                    <div className="text-muted mb-4">
                                        {getCms?.data?.length > 0 ? getCms?.data[0]?.email : ''}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8">
                        <div className="card border-0">
                            <div className="card-body p-0">
                                <ul className="nav nav-tabs nav-tabs-bordered position-sticky sticky-top bg-white settings-tabs"
                                    role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button aria-selected="false" className="nav-link active" data-bs-target="#primary-details"
                                            data-bs-toggle="tab" role="tab" tabindex="-1">Primary Details</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button aria-selected="true" className="nav-link" data-bs-target="#contact-details"
                                            data-bs-toggle="tab" role="tab">Contact</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button aria-selected="false" className="nav-link" data-bs-target="#credentials"
                                            data-bs-toggle="tab" role="tab" tabindex="-1">Credentials</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button aria-selected="false" className="nav-link" data-bs-target="#twofa"
                                            data-bs-toggle="tab" role="tab" tabindex="-1">2FA</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button aria-selected="false" className="nav-link" data-bs-target="#terms"
                                            data-bs-toggle="tab" role="tab" tabindex="-1">Terms</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button aria-selected="false" className="nav-link" data-bs-target="#policy"
                                            data-bs-toggle="tab" role="tab" tabindex="-1">CMS Policy</button>
                                    </li>
                                </ul>
                                <div className="tab-content p-3">
                                    <div className="tab-pane fade profile-overview active show" id="primary-details"
                                        role="tabpanel">
                                        <h5 className="card-title">
                                            About
                                        </h5>
                                        <p className="fst-italic">
                                            Sunt est soluta temporibus accusantium neque nam maiores cumque temporibus. Tempora
                                            libero non est unde veniam est qui dolor. Ut sunt iure rerum quae quisquam autem
                                            eveniet perspiciatis odit. Fuga sequi sed ea saepe at unde.
                                        </p>
                                        <h5 className="card-title">
                                            Profile Details
                                        </h5>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label text-black fw-bold">
                                                Name
                                            </div>
                                            <div className="col-lg-9 col-md-8">{response.data.name}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label text-black fw-bold">
                                                Branch
                                            </div>
                                            <div className="col-lg-9 col-md-8">{response.data.branch.branchName}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label text-black fw-bold">
                                                Post
                                            </div>
                                            <div className="col-lg-9 col-md-8">{response.data.post}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label text-black fw-bold">
                                                SUID
                                            </div>
                                            <div className="col-lg-9 col-md-8">{response.data.sUid}</div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade profile-overview" id="contact-details" role="tabpanel">
                                        <h5 className="card-title">
                                            Contact Details
                                        </h5>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label text-black fw-bold">
                                                Email
                                            </div>
                                            <div className="col-lg-9 col-md-8">{response.data.email}</div>
                                        </div>
                                        <p className=" text-muted mb-3">(This email will serve as the primary means for communicating
                                            important details and updates to enhance your overall experience.)</p>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label text-black fw-bold">
                                                Contact Number
                                            </div>
                                            <div className="col-lg-9 col-md-8">{response.data.phoneNumber}</div>
                                        </div>
                                        <p className=" text-muted mb-3">(All crucial communications will be conveyed through this
                                            designated contact number.)</p>
                                    </div>
                                    <div className="tab-pane fade profile-overview" id="credentials" role="tabpanel">

                                        <form>
                                            <div className="row mb-3">
                                                <label className="col-md-4 col-lg-3 col-form-label text-black fw-bold" for="email">Email</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input className="form-control" id="email" name="email" type="email" />
                                                </div>
                                            </div>
                                            <p className=" text-muted mb-3">If you suspect that your user ID is at risk of unauthorized
                                                access, please promptly contact the SGRS Utility Service Administrator to update
                                                your user ID. Failure to uphold account security measures may result in
                                                temporary or permanent suspension from all Utility services. Please refer to the
                                                terms and conditions for further information.</p>
                                            <p className=" text-muted mb-3">To update your ID and password, enter your current password,
                                                input your new password, undergo authentication via 2FA, and then proceed to
                                                change your password.</p>
                                            <div className="row mb-3">
                                                <label className="col-md-4 col-lg-3 col-form-label text-black fw-bold" for="currentPassword ">Current
                                                    Password</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input className="form-control" id="currentPassword" name="password"
                                                        type="password" />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label className="col-md-4 col-lg-3 col-form-label text-black fw-bold" for="newPassword">New
                                                    Password</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input className="form-control" id="newPassword" name="newpassword"
                                                        type="password" />
                                                </div>
                                            </div>
                                            <div className="text-end">
                                                <button className="btn btn-primary " type="submit">Change Password</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="tab-pane fade profile-overview" id="twofa" role="tabpanel">

                                        <form>
                                            <div className="row mb-3">
                                                <label className="col-md-4 col-lg-3 col-form-label text-black fw-bold" for="mail">Email</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input className="form-control" id="mail" name="mail" type="email" />
                                                </div>
                                            </div>
                                            <p className=" text-muted mb-3">(This email will serve as your secondary factor for
                                                authentication.)</p>
                                            <div className="row mb-3">
                                                <label className="col-md-4 col-lg-3 col-form-label text-black fw-bold" for="contactnumber">Contact
                                                    Number</label>
                                                <div className="col-md-8 col-lg-9">
                                                    <input className="form-control" id="numbermob" name="mobnumber" type="tel" />
                                                </div>
                                            </div>
                                            <p className=" text-muted mb-3">(This contact number will be employed as your secondary factor
                                                for authentication. Please note that 2FA via contact number is currently
                                                unavailable.)</p>
                                            <div className="text-end">
                                                <button className="btn btn-primary" type="submit">Save Settings</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="tab-pane fade profile-overview" id="terms" role="tabpanel">
                                        <div className="d-flex gap-5">
                                            <div className='fw-bold text-danger'>Terms and Conditions</div>


                                        </div>

                                        <div className='flex-grow-1 bg-white pt-4 px-3' dangerouslySetInnerHTML={{ __html: getTerms?.data[0]?.content }}>



                                        </div>
                                        <div className='text-start fw-bold p-3'>
                                            Accepted <span className='text-dark fs-11'>
                                                {getTerms?.data[0]?.updatedAt ? new Date(getTerms.data[0].updatedAt).toLocaleDateString() : ''}
                                            </span>
                                        </div>

                                    </div>

                                    <div className="tab-pane fade profile-overview" id="policy" role="tabpanel">
                                        <div className="d-flex gap-5">
                                            <div className='fw-bold text-danger'>Policy</div>


                                        </div>

                                        <div className='flex-grow-1 bg-white pt-4 px-3' dangerouslySetInnerHTML={{ __html: getPolicy?.data[0]?.content }}>



                                        </div>
                                        <div className='text-start fw-bold p-3'>
                                            Accepted <span className='text-dark fs-11'>
                                                {getPolicy?.data[0]?.updatedAt ? new Date(getPolicy.data[0].updatedAt).toLocaleDateString() : ''}
                                            </span>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )


    return (
        <div className="container h-100">

            <div className="row bg-success h-100">

                <div className="col bg-danger">
                    <div className="bg-white  text-center h-100 d-flex flex-column align-items-center justify-content-center">
                        <img src={Api.SUPERADMIN_URL + `uploads/images/profilePictures/${response.data.profilePicture.id}${response.data.profilePicture.ext}`} alt="" className='borde rounded-circle shadow-sm' style={{ width: 100, height: 100 }} />
                        <p className='fw-bold'>{response.data.name}</p>
                        <p className='fs-11'>{response.data.post}</p>
                        <hr className='border' />
                    </div>
                </div>

                <div className="col">Tabs</div>

            </div>

        </div>
    )
}

export default SettingsV1