import React from "react";
import Input from "../../components/form/Input";
import { useLoginMutation } from "../../services/authSlice";
import logo from "../../assets/gif/portals.gif";
import logoSmall from "../../assets/images/icgs_sm.png";
import { useDispatch } from "react-redux";
import { setAuthToken } from "../../redux/authSlice";
import { useNavigate } from "react-router-dom";

const SignIn = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, loginResponse] = useLoginMutation({ fixedCacheKey: "signIn" });

  const submitBtnClassname = "btn btn-primary mb-3 rounded-pill px-5";

  const onSubmit = (event) => {
    event.preventDefault();

    login({
      username: event.target["email"].value,
      password: event.target["password"].value,
    }).then((res) => {
      if (!res.error) {
        navigate('/singleSignOn')
        dispatch(setAuthToken(res.data.token));
      }
    });
  };

  return (
    <div className="d-flex signInBackground">
      <div className="d-flex align-items-center justify-content-center h-100 w-50">
        <form
          className="p-4 bg-white w-50 rounded-5 shadow"
          onSubmit={onSubmit}
        >
          <div className="text-center">
            <img src={logo} alt="" width={250} className="mb-3" />
          </div>

          <div className="">
            <Input name={"email"} containerClass={" mb-3"} />
          </div>

          <div className="">
            <Input
              name={"password"}
              type={"password"}
              containerClass={" mb-3"}
            />
          </div>

          {loginResponse.isLoading ? (
            <button className={submitBtnClassname} type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>{" "}
              Logging in...
            </button>
          ) : (
            <button className={submitBtnClassname}>Login</button>
          )}
        </form>
      </div>

      <div className="h-100 w-50 d-flex align-items-start justify-content-center">
        <div className="d-none card p-3 border-0 rounded-0 rounded-bottom-4">
          <img src={logoSmall} alt="" width={100} />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
