import React from "react";
import userImg from "../assets/images/userPhoto.png";
import userImg2 from "../assets/images/user2.png";
import {
  useGetNoticesQuery,
  useGetTutorialByIdQuery,
  useGetTutorialsQuery,
  useGetUserDetailsQuery,
} from "../services/userSlice";
import Loading from "../components/ui/Loading";
import ApiErrorModal from "../components/modals/ApiErrorModal";
import ViewNoticeModal from "../components/modals/ViewNoticeModal";
import Api from "../constants/Api";
import Slider from "../components/ui/Slider";
import { Link } from "react-router-dom";
import { useGetBranchesQuery } from "../services/branchSlice";
import Icons from "../components/ui/Icons";
import Image from "../components/ui/Image";

const Dashboard = () => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let maxMarkAsReadLength = 0;
  const currentDate = new Date();

  const userRes = useGetUserDetailsQuery();
  const noticeRes = useGetNoticesQuery();
  const tutorialRes = useGetTutorialsQuery();
  const branchRes = useGetBranchesQuery();

  console.log(userRes.data)

  if (noticeRes.isLoading || userRes.isLoading || tutorialRes.isLoading)
    return <Loading />;
  if (noticeRes.isError) return <ApiErrorModal response={noticeRes} />;
  if (userRes.isError) return <ApiErrorModal response={userRes} />;

  const sortedNotices = noticeRes.isSuccess
    ? [...noticeRes.data].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    )
    : []; 

  // const releasedTutorials = tutorialRes.data.filter((item) => {
    
  //   const currentDateObject = new Date();

  //   // Check if item.publishInUserApp is truthy and item.releaseDate is a valid ISO date string
  //   if (item.publishInUserApp && item.releaseDate) {
  //     const releaseDate = new Date(item.releaseDate);

  //     // Check if releaseDate is a valid Date object
  //     if (!isNaN(releaseDate.getTime())) {
  //       // Extract only the date part (year, month, day) for comparison
  //       const releaseDateOnly = new Date(
  //         releaseDate.getFullYear(),
  //         releaseDate.getMonth(),
  //         releaseDate.getDate()
  //       );
  //       const currentDateOnly = new Date(
  //         currentDateObject.getFullYear(),
  //         currentDateObject.getMonth(),
  //         currentDateObject.getDate()
  //       );

  //       return releaseDateOnly <= currentDateOnly;
  //     } else {
  //       console.warn(`Invalid releaseDate: ${item.releaseDate}`);
  //     }
  //   }

  //   return false;
  // });

  const releasedTutorials = tutorialRes.data.filter((item) => {
    const releaseDate = new Date(item.releaseDate);
    return item.publishInUserApp && releaseDate <= currentDate;
  });

  userRes.data.branch.users.map((item) => {
    const userMarkAsReadLength = releasedTutorials.reduce((len, tut) => {
      return (
        len +
        tut.markAsReads.filter(
          (markAsReadItem) => markAsReadItem.userId == item.id
        ).length
      );
    }, 0);

    maxMarkAsReadLength = Math.max(maxMarkAsReadLength, userMarkAsReadLength);
  });

  console.log("maxMarkAsReadLength:", maxMarkAsReadLength);
  console.log("releasedTutorials.length:", releasedTutorials.length);
  const progressPercentage =
    (maxMarkAsReadLength / (releasedTutorials.length || 1)) * 100;
  console.log("progressPercentage:", progressPercentage);

  // ========================

  const calculateTutorials = (branchId, users) => {
    const taughtTutorials = releasedTutorials.filter((tutorial) => {
      return users.some((user) =>
        tutorial.markAsReads.some((read) => read.userId == user.id)
      );
    });

    const untaughtTutorials = releasedTutorials.filter((tutorial) => {
      return !users.some((user) =>
        tutorial.markAsReads.some((read) => read.userId == user.id)
      );
    });

    return {
      taughtTutorials: taughtTutorials.length,
      untaughtTutorials: untaughtTutorials.length,
    };
  };

  // Create an array of branch objects with performance metrics
  const branchPerformances = branchRes?.data?.map((data) => {
    const tutorials = calculateTutorials(data.id, data.users);
    return {
      branchId: data.id,
      branchName: data.branchName,
      mainBranch: data.mainBranch,
      status: data.status,
      totalTutors: data.users.length,
      taughtTutorials: tutorials.taughtTutorials,
      untaughtTutorials: tutorials.untaughtTutorials,
    };
  });

  const sortedBranches = branchPerformances?.sort(
    (a, b) => b.taughtTutorials - a.taughtTutorials
  );

  const currentBranchIndex = sortedBranches?.findIndex(
    (item) => item.branchId == userRes.data.branch.id
  );

  let currentBranchRanking;

  if (currentBranchIndex !== -1) {
    currentBranchRanking = currentBranchIndex + 1;
  } else {
    currentBranchRanking = 0;
  }

  return (
    <>
      <div className="container-fluid px-4 py-3 h-100 d-lg-block d-none">
        <div className="row h-100 p-3">
          <div className="col-8 d-flex flex-column h-100">
            <Slider type="Dashboard" />

            <div className="d-flex flex-grow-1 flex-column bg-white rounded-5 p-3 shadow ">
              <div className="border-bottom pb-2 fs-6 fw-bol py-2 mb-2 text-center">
                Welcome to the ICGS Brahmvidhya Portal. This CMS portal is
                managed SPMK(Brahmvidhya).
              </div>
              <div className=" d-flex hide-scroll flex-grow-1">
                <div className="border-right border-end w-75 ">
                  <div className="d-flex align-items-center ">
                    <Image
                      src={
                        Api.SUPERADMIN_URL +
                        `uploads/images/profilePictures/${userRes?.data?.branch.profilePicture?.id}${userRes.data.branch.profilePicture?.ext}`
                      }
                      alt="profile"
                      className="rounded-circle "
                      style={{
                        maxWidth: 90,
                        height: 90,
                        objectFit: "fill", // Add this line
                      }}
                      icon={() =>
                        Icons.defaultUser("text-tertiary ", {
                          width: "100%",
                          height: "100%",
                        })
                      }
                    />

                    <div className="ps-3">
                      <div>
                        {userRes.data.branch.branchName}:{" "}
                        {userRes.data.branch.address}
                      </div>
                      <div>GUID: {userRes.data.branch.id}</div>
                    </div>
                  </div>
                  <div className="my-2">
                    <div className="fs-10 text-start">
                      Branch Progress is at{" "}
                      {isFinite(progressPercentage)
                        ? Math.floor(progressPercentage)
                        : 0}
                      %
                    </div>

                    <div className="progress w-75 shadow">
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                        role="progressbar"
                        style={{
                          width: `${isFinite(progressPercentage)
                            ? progressPercentage
                            : 0
                            }%`,
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className="px-3 pt-3 mb-2 mt-3 container">
                    <div className="row row-cols-3 g-3 justify-content-center mx-4">
                      <div className="col">
                        <div>
                          <div className="bg-white text-center shadow p-3 fs-13">
                            <div className=" fs-5">
                              {releasedTutorials.length}
                            </div>
                            <div className="fs-6">
                              Tutorials <br />
                              Released
                            </div>
                          </div>
                          <div
                            className="bg-info"
                            style={{ marginTop: -9, height: 9 }}
                          ></div>
                        </div>
                      </div>

                      <div className="col">
                        <div>
                          <div className="bg-white text-center shadow p-3 fs-13 ">
                            <div className=" fs-5">
                              {userRes.data.branch.users.length}
                            </div>
                            <div className="fs-6">
                              Registered <br />
                              Tutors
                            </div>
                          </div>
                           <div
                            className="bg-warning"
                            style={{ marginTop: -9, height: 9 }}
                          ></div>
                        </div>
                      </div>

                      <div className="col">
                        <div>
                          <div className="bg-white text-center shadow p-3 fs-13 ">
                            <div className=" fs-5">
                              {currentBranchRanking}th
                            </div>
                            <div className="fs-6">
                              in <br /> G-Ranking
                            </div>
                          </div>
                          <div
                            className="bg-danger"
                            style={{ marginTop: -9, height: 9 }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className=" w-50 px-2 overflow-auto"
                  style={{ maxHeight: "300px" }}
                >
                  <table className="table table-border">
                    <thead>
                      <tr>
                        <th className=" sticky-top" scope="col">
                          Member
                        </th>
                        <th className=" sticky-top" scope="col">
                          Tutorial Taken
                        </th>
                      </tr>
                    </thead>
                    <tbody className="overflow-y-auto">
                      {userRes.data.branch.users.map((item, i) => {
                        const userMarkAsReadLength = tutorialRes.data.reduce(
                          (len, tut) => {
                            return (
                              len +
                              tut.markAsReads.filter(
                                (markAsReadItem) =>
                                  markAsReadItem.userId === item.id
                              ).length
                            );
                          },
                          0
                        );

                        maxMarkAsReadLength = Math.max(
                          maxMarkAsReadLength,
                          userMarkAsReadLength
                        );

                        return (
                          <>
                            <tr className="" key={i}>
                              <td className="d-flex ">
                                {/* <img src={Api.SUPERADMIN_URL + `uploads/images/profilePictures/${item?.profilePicture?.id}${item?.profilePicture?.ext}`} className='rounded-circle' alt="" style={{ width: 30, height: 30 }} /> */}
                                <Image
                                  src={
                                    Api.SUPERADMIN_URL +
                                    `uploads/images/profilePictures/${item?.profilePicture?.id}${item?.profilePicture?.ext}`
                                  }
                                  alt="profile"
                                  className="rounded-circle "
                                  style={{ width: 30, height: 30 }}
                                  icon={() =>
                                    Icons.defaultUser("", {
                                      width: "100%",
                                      height: "100%",
                                    })
                                  }
                                />
                                <div className="ms-2">{item.name}</div>
                              </td>

                              <td className="text-center">
                                <span className="">
                                  {
                                    tutorialRes.data.filter((tut) => {
                                      return tut.markAsReads.some(
                                        (markAsReadItem) =>
                                          markAsReadItem.userId === item.id
                                      );
                                    }).length //calculatte maximum lenth of all user
                                  }
                                </span>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-4 h-100">
            <div className="card px-5 rounded-5  h-100 d-flex flex-column hide-scroll shadow">
              <div className="text-center overflow-hidden mb-3  pb-5">
                <span className="text-white shadow text-center bg-primary rounded-bottom-4 px-5 py-2 mb-3 fs-4">
                  Notices
                </span>
              </div>

              <div className="d-flex flex-column overflow-hidden flex-grow-1 ">
                <div className="overflow-auto flex-grow-1 align-items-center justify-content-center">
                  {sortedNotices
                    .filter(
                      (item) =>
                        new Date(item.date) > new Date(userRes.data.createdAt)
                    )
                    .map((item) => (
                      <div key={item.id}>
                        <div className="d-flex align-items-center">
                          <div className="bg-secondary py-2 px-3 rounded-4 text-white d-flex flex-column justify-content-center me-3">
                            <h3>
                              {new Date(item.date).getDate() < 10
                                ? "0" + new Date(item.date).getDate()
                                : new Date(item.date).getDate()}
                            </h3>
                            <p className="fs-13 text-center">
                              {months[new Date(item.date).getMonth()]}
                            </p>
                          </div>
                          <p className="fw-bold">
                            {item.title} <ViewNoticeModal id={item.id} />
                          </p>
                        </div>
                        <hr className="text-light" />
                      </div>
                    ))}
                </div>
                <div className="d-flex justify-content-center my-3">
                  {/* <Link to={'notice/create'} className="btn btn-primary rounded-pill px-5">Create</Link> */}
                  <Link
                    className="btn btn-primary rounded-pill px-5 ms-4 "
                    to={"notice"}
                  >
                    History
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid px-4 py-3  d-lg-none ">
        <div className="row d-flex flex-column w-100 p-3">
          <div className="col-8 d-flex flex-column w-100">
            <Slider type="Dashboard" id={"dashboardSlider111212"} />

            <div className="d-flex  flex-column bg-white rounded-5 p-3 shadow flex-grow-1 ">
              <div className="border-bottom pb-2 fs-6 fw-bol py-2 mb-2 text-center">
                Welcome to the ICGS Brahmvidhya Portal. This CMS portal is
                managed SPMK(Brahmvidhya).
              </div>
              <div className=" d-flex hide-scroll flex-grow-1 flex-column">
                <div className="border-right border-end ">
                  <div className="d-flex align-items-center ">
                    <img
                      src={
                        Api.SUPERADMIN_URL +
                        `uploads/images/profilePictures/${userRes?.data?.branch.profilePicture?.id}${userRes.data.branch.profilePicture?.ext}`
                      }
                      className="rounded-circle bg-"
                      alt="failed to load"
                      style={{
                        maxWidth: 70,
                        height: 70,
                        objectFit: "fill", // Add this line
                      }}
                    />

                    <div className="ps-3">
                      <div>
                        {userRes.data.branch.branchName}:{" "}
                        {userRes.data.branch.address}
                      </div>
                      <div>GUID: {userRes.data.branch.id}</div>
                    </div>
                  </div>
                  <div className="my-2">
                    <div className="fs-10 text-start">
                      Branch Progress is at{" "}
                      {isFinite(progressPercentage)
                        ? Math.floor(progressPercentage)
                        : 0}
                      %
                    </div>

                    <div className="progress w-75 shadow">
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                        role="progressbar"
                        style={{
                          width: `${isFinite(progressPercentage)
                            ? progressPercentage
                            : 0
                            }%`,
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className="px-3 pt-3 mb-2 mt-3 container">
                    <div className="row row-cols-2 justify-content-center g-3">
                      <div className="col">
                        <div className="bg-white text-center shadow p-3 fs-13  h-100 ">
                          <div className=" fs-5">
                            {releasedTutorials.length}
                          </div>
                          <div className="fs-6">Tutorials Released</div>
                        </div>
                        <div
                          className="bg-info"
                          style={{ marginTop: -9, height: 9 }}
                        ></div>
                      </div>

                      <div className="col">
                        <div className="bg-white text-center shadow p-3 fs-13  h-100 ">
                          <div className=" fs-5">
                            {userRes.data.branch.users.length}
                          </div>
                          <div className="fs-6">Registered Tutors</div>
                        </div>
                        <div
                          className="bg-warning"
                          style={{ marginTop: -9, height: 9 }}
                        ></div>
                      </div>

                      <div className="col">
                        <div className="bg-white text-center shadow p-3 fs-13  h-100 ">
                          <div className=" fs-5">{currentBranchRanking}th</div>
                          <div className="fs-6">in G-Ranking</div>
                        </div>
                        <div
                          className="bg-danger"
                          style={{ marginTop: -9, height: 9 }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="overflow-y-auto px-2  overflow-auto"
                  style={{ maxHeight: "344px" }}
                >
                  <table className="table table-border ">
                    <thead>
                      <tr>
                        <th className=" sticky-top" scope="col">
                          Member
                        </th>
                        <th className=" sticky-top" scope="col">
                          Tutorial Taken
                        </th>
                      </tr>
                    </thead>
                    <tbody className="overflow-y-auto">
                      {userRes.data.branch.users.map((item, i) => {
                        const userMarkAsReadLength = tutorialRes.data.reduce(
                          (len, tut) => {
                            return (
                              len +
                              tut.markAsReads.filter(
                                (markAsReadItem) =>
                                  markAsReadItem.userId === item.id
                              ).length
                            );
                          },
                          0
                        );

                        maxMarkAsReadLength = Math.max(
                          maxMarkAsReadLength,
                          userMarkAsReadLength
                        );

                        return (
                          <>
                            <tr className="" key={i}>
                              <td className="d-flex ">
                                {/* <img src={userImg} style={{ width: 30 }} alt="" /> */}
                                <img
                                  src={
                                    Api.SUPERADMIN_URL +
                                    `uploads/images/profilePictures/${item?.profilePicture?.id}${item?.profilePicture?.ext}`
                                  }
                                  className="rounded-circle"
                                  alt=""
                                  style={{ width: 30, height: 30 }}
                                />
                                <div className="ms-2">{item.name}</div>
                              </td>

                              <td className="text-center">
                                <span className="">
                                  {
                                    tutorialRes.data.filter((tut) => {
                                      return tut.markAsReads.some(
                                        (markAsReadItem) =>
                                          markAsReadItem.userId === item.id
                                      );
                                    }).length //calculatte maximum lenth of all user
                                  }
                                </span>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-4 w-100">
            <div className="card px-5 rounded-5  h-100 d-flex flex-column hide-scroll shadow">
              <div className="text-center overflow-hidden mb-3  pb-5">
                <span className="text-white shadow text-center bg-primary rounded-bottom-4 px-5 py-2 mb-3 fs-4">
                  Notices
                </span>
              </div>

              <div className="d-flex flex-column overflow-hidden flex-grow-1 ">
                <div className="overflow-auto flex-grow-1 align-items-center justify-content-center">
                  {sortedNotices
                    .filter(
                      (item) =>
                        new Date(item.date) > new Date(userRes.data.createdAt)
                    )
                    .map((item) => (
                      <div key={item.id}>
                        <div className="d-flex align-items-center">
                          <div className="bg-secondary py-2 px-3 rounded-4 text-white d-flex flex-column justify-content-center me-3">
                            <h3>
                              {new Date(item.date).getDate() < 10
                                ? "0" + new Date(item.date).getDate()
                                : new Date(item.date).getDate()}
                            </h3>
                            <p className="fs-13 text-center">
                              {months[new Date(item.date).getMonth()]}
                            </p>
                          </div>
                          <p className="fw-bold">
                            {item.title} <ViewNoticeModal id={item.id} />
                          </p>
                        </div>
                        <hr className="text-light" />
                      </div>
                    ))}
                </div>
                <div className="d-flex justify-content-center my-3">
                  {/* <Link to={'notice/create'} className="btn btn-primary rounded-pill px-5">Create</Link> */}
                  <Link
                    className="btn btn-primary rounded-pill px-5 ms-4 "
                    to={"notice"}
                  >
                    History
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
