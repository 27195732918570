import React, { useEffect, useRef, useState } from 'react'
import { useCreateSubmitAssignmentMutation, useCreateSubmitTutorialMutation, useGetAssignmentByIdQuery, useGetTutorialByIdQuery, useGetTutorialsQuery, useGetUserDetailsQuery, usePatchSubmitAssignmentMutation, usePatchSubmitTutorialMutation } from '../../services/userSlice'
import FormattedInput from '../form/FormattedInput'
import Loading from '../ui/Loading'
import Icons from '../ui/Icons'
import Button from '../form/Button'
import UploadImage from '../ui/UploadImage'
import NewUploadImage from '../ui/NewUploadImage'
import InputTextArea from '../form/InputTextArea'
import { useReactMediaRecorder } from "react-media-recorder";

const ViewSubmitModal = ({ id, tutorial, createAbout, tutorialSubmit, createAboutUpdate, tutorialSubmitUpdate, createAboutRes, tutorialSubmitRes }) => {

    const modalId = "ViewSubmitModal"


    const [second, setSecond] = useState("00");
    const [minute, setMinute] = useState("00");
    const [isActive, setIsActive] = useState(false);
    const [counter, setCounter] = useState(0);


    const aboutRes = useGetAssignmentByIdQuery(id)
    const userRes = useGetUserDetailsQuery()
    const tutorialRes = useGetTutorialByIdQuery(id)

    const [images, setImages] = useState([])

    const [isRecording, setIsRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);

 
    const {
        status,
        startRecording,
        stopRecording,
        pauseRecording,
        mediaBlobUrl
    } = useReactMediaRecorder({
        video: false,
        audio: true,
        echoCancellation: true
    });


    useEffect(() => {
        let intervalId;

        if (isActive) {
            intervalId = setInterval(() => {
                const secondCounter = counter % 60;
                const minuteCounter = Math.floor(counter / 60);

                let computedSecond =
                    String(secondCounter).length === 1
                        ? `0${secondCounter}`
                        : secondCounter;
                let computedMinute =
                    String(minuteCounter).length === 1
                        ? `0${minuteCounter}`
                        : minuteCounter;

                setSecond(computedSecond);
                setMinute(computedMinute);

                setCounter((counter) => counter + 1);
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [isActive, counter]);





    function stopTimer() {
        setIsActive(false);
        setCounter(0);
        setSecond("00");
        setMinute("00");
    }
 
    console.log("url", mediaBlobUrl);


    let isSubmit;
    let defaultVal;

    const fileInputRef = useRef();

    const onSubmit =async (e) => {
        e.preventDefault();

        const submissionId = e.target['submissionId'].value;
        const content = e.target['formattedInput'].value;
        const formData = new FormData();

        formData.append('content', content);

        // Append each image to the FormData
        images.forEach((image, index) => {
            formData.append(`images`, image.file);
        });

        

        if (mediaBlobUrl) {
            const audioBlob = await fetch(mediaBlobUrl).then(res => res.blob());
            formData.append('audio', audioBlob, 'audio.wav');
        }
    

        // Check if it's a tutorial submission or assignment submission
        if (!isSubmit) {
            if (tutorial) {
                tutorialSubmit({ id, body: formData })
                    .then(() => {
                        e.target.reset();
                        setImages([]);
                        setAudioBlob(null);
                        stopTimer();
                        tutorialRes.refetch()
                    })
            } else {
                createAbout({ id, body: formData }).then((res) => {
                    if (!res.error) {
                        e.target.reset();
                        setImages([]);
                        setAudioBlob(null);
                        stopTimer();
                        aboutRes.refetch();
                    }
                });
            }
        } else {
            if (tutorial) {
                tutorialSubmit({ id, body: formData })
                    .then(() => {
                        e.target.reset();
                        setImages([]);
                        setAudioBlob(null);
                        stopTimer();
                        tutorialRes.refetch()
                    })
            } else {
                createAbout({ id, body: formData })
                    .then(() => {
                        e.target.reset();
                        setImages([]);
                        setAudioBlob(null);
                        stopTimer();
                        aboutRes.refetch()
                    })
            }
        }
    };




    if (aboutRes.isLoading || userRes.isLoading || tutorialRes.isLoading) return <Loading />

    if (tutorial) {
        isSubmit = tutorialRes.data.submissions.filter((item) => item.tutorialId == id && item.userId == userRes.data.id).length > 0;
        defaultVal = tutorialRes.data.submissions.filter((item) => item.tutorialId == id && item.userId == userRes.data.id)[0];

    }
    else {
        isSubmit = aboutRes.data.submissions.filter((item) => item.assignmentId == id && item.userId == userRes.data.id).length > 0
        defaultVal = aboutRes.data.submissions.filter((item) => item.assignmentId == id && item.userId == userRes.data.id)[0];


    }


    return (
        <>
            {/* <Link type="button" className="text-danger fw-bold hidden" data-bs-toggle="modal" data-bs-target={"#" + "ViewSubmitModal"}>
                See Now
            </Link> */}



            <div className="modal fade" id={modalId} tabindex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <form onSubmit={onSubmit} className="modal-content rounded-4">
                        <div className="modal-header d-flex justify-content-center p-0">
                            <h1 className="modal-title fs-5 bg-primary text-white px-3 py-1 rounded-bottom-4 mb-3" id={modalId + "Label"}>Submission</h1>
                        </div>
                        <div className="modal-body">
                            {
                                // isSubmit && <div className='text-success fs-4'>Already Submit </div>
                            }

                            <div>

                                <input type="hidden" name='submissionId' />

                                {/* <FormattedInput /> */}

                                <InputTextArea name='formattedInput' placeholder={'Enter Your Content Here....'} containerClass={'bg-danger mb-3 h-100 bg-white '} style={{ height: 350 }} />
                                <div className="mb-3">

                                   


                                    <div className='' style={{ border: "1px solid black", backgroundColor: "black" }}>
                                        <div style={{ border: "1px solid #bd9f61", height: "70px", backgroundColor: "#bd9f61", display: "flex", alignItems: "center", paddingLeft: "10px" }}>
                                            <h4 style={{ textTransform: "capitalize", fontFamily: "sans-serif", fontSize: "18px", color: "white" }}>
                                                {status}
                                            </h4>
                                        </div>



                                        <div style={{ backgroundColor: "black", color: "white", textAlign: "center" }}>
                                            <button
                                                style={{ backgroundColor: "black", borderRadius: "8px", color: "white", marginBottom: "10px" }}
                                                type='button'
                                                onClick={stopTimer}
                                            >
                                                Clear
                                            </button>
                                            <div style={{ fontSize: "54px" }}>
                                                <span className="minute">{minute}</span>
                                                <span>:</span>
                                                <span className="second">{second}</span>
                                            </div>
                                            <div >
                                                <h4 className='mb-1'>Press the Start to record</h4>
                                                <div>
                                                    <button
                                                        type='button'
                                                        className='btn btn-success mx-2'
                                                        onClick={() => {
                                                            if (!isActive) {
                                                                startRecording();
                                                            } else {
                                                                pauseRecording();
                                                            }
                                                            setIsActive(!isActive);
                                                        }}
                                                    >
                                                        {isActive ? "Pause" : "Start"}
                                                    </button>
                                                    <button
                                                        type='button'
                                                        className='btn btn-danger mx-2 '
                                                        onClick={() => {
                                                            stopRecording();
                                                            pauseRecording();
                                                        }}
                                                        disabled={isActive}
                                                    >
                                                        Stop
                                                    </button>
                                                </div>

                                                {
                                                    (mediaBlobUrl) && <div className='  my-2 p-0'>
                                                        <audio className='m-0 p-0' src={mediaBlobUrl} controls />
                                                    </div>
                                                }




                                            </div>
                                        </div>



                                    </div>













                                    <NewUploadImage images={images} setImages={setImages} />
                                </div>

                            </div>


                        </div>
                        <div className="modal-footer">
                            {/* <button className="btn btn-primary text-white rounded-pill px-4" type='submit '>Submit</button> */}
                            <Button res={tutorial ? tutorialSubmitRes : createAboutRes} className='btn btn-primary text-white rounded-pill px-4 ' type='submit '>Submit</Button>
                            <button className="btn btn-primary text-white rounded-pill px-4" data-bs-dismiss="modal">Close</button>

                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ViewSubmitModal