import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useCreateSuggestionMutation } from '../../services/userSlice';
import FormattedInput from '../form/FormattedInput';

const EditSuggestionModal = ({ id, type }) => {

    const modalId = "editAboutModal" + id;
    const formattedInputName = "formattedInputName" + id;
    const [selectedImage, setSelectedImage] = useState(null);
    const [createSuggestion, createSuggestionRes] = useCreateSuggestionMutation();

    const handlePublish = async (e) => {
        e.preventDefault();


        let body = new FormData();

        body.append('content', e.taret[formattedInputName].value);
        if (selectedImage) {
            body.append('image', selectedImage);
        }

        // You may want to await the mutation call
        const res = await createSuggestion({ id, body });

        if (!res.error) {
            // Handle success, refetch, or any other action
        }
    };


    return (
        <>
            <Link
                type="button"
                className='btn fw-bold px-4 py-2 rounded-pill border border-white border-3'
                style={{ backgroundColor: '#EEB217' }}
                data-bs-toggle="modal"
                data-bs-target={"#" + modalId}
            >
                Give Suggestion
            </Link>

            <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content rounded-4">
                        <div className="modal-header d-flex justify-content-center p-0">
                            <h1 className="modal-title fs-5 bg-primary text-white px-3 py-1 rounded-bottom-4 mb-3" id={modalId + "Label"}>Suggestion</h1>
                        </div>
                        <form onSubmit={handlePublish}>
                            <div className="modal-body">
                                <FormattedInput name={formattedInputName} label={""} />
                                <input type="file" name='image' onChange={(e) => setSelectedImage(e.target.files[0])} />

                            </div>
                            <div className="modal-footer">
                                {/* <button type="submit" className="btn btn-primary text-white rounded-pill px-4" >Save</button> */}
                                <button type="submit" className="btn btn-primary text-white rounded-pill px-4" data-bs-dismiss="modal">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditSuggestionModal;
