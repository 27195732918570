import React from 'react';
import CircularProgressBar from '../../components/ui/CircularProgressBar';

import sessionOne from '../../assets/images/sessionOne.png'
import sessionTwo from '../../assets/images/sessionTwo.png'
import sessionThree from '../../assets/images/sessionThree.png'
import sessionFour from '../../assets/images/sessionFour.png'

function TrainingCompletion({ totalSessions, completedSessions }) {

  const progress = (completedSessions / totalSessions) * 100;





  const showBadge = progress === 100;



  return (

    // card rounded-5 overflow-hidden flex-row shadow w-75
    <div className=' card rounded-5 ms-2 bg-white px-3 d-flex align-items-center overflow-hidden hide-scroll shadow h-100' style={{width:500}}>

      <img src={sessionFour} />

      <div className="d-flex mb-4 mt-2 gap-4">

        <CircularProgressBar progress={progress} big />
        <h3 className='mt-4'>Training Progress Report</h3>
        {/* <div className='fw-bold fs-5'>{completedSessions}/{totalSessions} Sessions Completed</div>
        <div className='fw-bold my-3 fs-5'>{text}</div>
        {showBadge && <div className='btn btn-pill bg-danger text-white rounded-pill w-50 '>Badge</div>} */}

      </div>

      <div className='d-flex gap-2 ms-auto h-100 overflow-auto'>
        <div className=' d-flex h-100 flex-column'>

   
           
          <div className={`d-flex h-100 ms-2 gap-3 align-items-center  border-start border-5 px-3  py-2 position-relative ${(progress >= 70 && progress < 100) ? ' border-secondary' : ' '}`}>
            {
              (progress >= 70 && progress <= 100) &&
              <div className='bg-danger rounded-circle position-absolute' style={{ minHeight: 20, minWidth: 20, marginLeft: -28, marginBottom: 70 }}></div>
            }
            <img src={sessionOne} alt='icon' style={{ height: 70, width: 70 }} />
            <p className='p-0'><b>Proficient (Lv 3)</b> <br /> Impressive! You've reached the Proficient level, mastering the material. Continue to refine your expertise and apply what you've learned.</p>
          </div>

          <div className={`d-flex ms-2 h-100  gap-3 align-items-center  border-start border-5 px-3 py-2 position-relative ${(progress >= 40 && progress < 100) ? ' border-secondary' : ' '}`}>

            {
              (progress >= 40 && progress < 70) &&
              <div className='bg-danger rounded-circle position-absolute' style={{ minHeight: 20, minWidth: 20, marginLeft: -28, marginBottom: 70 }}></div>
            }
            <img src={sessionTwo} alt='icon' style={{ height: 70, width: 70 }} />
            <p><b>Acquainted (Lv 2)</b><br /> Well done! You're now at the Acquainted level, navigating through the content with familiarity. Keep up the momentum!</p>
          </div>

          <div className={`d-flex ms-2 h-100 gap-3 align-items-center  border-start border-5 px-3 mb-4 py-2 position-relative ${(progress >= 20 && progress < 100) ? ' border-secondary' : ' '}`}>

            {
              (progress >= 20 && progress < 40) &&
              <div className='bg-danger rounded-circle position-absolute' style={{ minHeight: 20, minWidth: 20, marginLeft: -28, marginBottom: 70 }}></div>
            }

            <img src={sessionThree} alt='icon' style={{ height: 70, width: 70 }} />
            <p><b>Learner (Lv 1) </b><br /> Congratulations on starting your learning journey! You've taken the first step towards greatness</p>
          </div>

        </div>
      </div>



    </div>
  );
}

export default TrainingCompletion;
