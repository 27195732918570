import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetModuleByIdQuery, useGetUserDetailsQuery } from '../../services/userSlice'
import logo from '../../assets/images/icgs_logo.png'
import poster from '../../assets/images/bg.png'

import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import Rating from 'react-rating'
import Input from '../../components/form/Input'
import Icons from '../../components/ui/Icons'
import SuccessModal from '../../components/modals/SuccessModal'
import VideoPlayer from '../../components/ui/VideoPlayer'
import { useCreateSessionRatingMutation, useToggleSessionReadMutation } from '../../services/sessionSlice'
import SessionReadButton from '../../components/form/SessionReadButton'

const Sessions = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const response = useGetModuleByIdQuery(id)
    const getUser = useGetUserDetailsQuery()

    const [markRead, markReadRes] = useToggleSessionReadMutation()

    const [createSessionRating, createSessionRatingRes] = useCreateSessionRatingMutation()

    const [rating, setRating] = useState(0)
    const [review, setReview] = useState(0)


    const [selectedSessionTab, setSelectedSessionTab] = useState();
    const activeSessionTabClassName = "flex-grow-1 bg-white py-3 text-center border-end border-5 border-danger fw-bold";
    const inactiveSessionTabClassName = "flex-grow-1 bg-light py-3 text-center";

    const [selectedTab, setSelectedTab] = useState("Overview");
    const activeTabClassName = "flex-grow-1 bg-light py-2 text-center border-bottom border-5 border-danger fw-bold";
    const inactiveTabClassName = "flex-grow-1 bg-primary py-2 text-center text-white";

    const handleSessionRead = (id) => {
        markRead(id)
            .then(() => response.refetch())
    }


    const handleRating = (e) => {
        e.preventDefault();

        let body = {
            "ratingValue": rating,
            "review": review
        }

        createSessionRating({ id, body })
    }

    const getVideoOptions = (videoLink) => ({
        controls: true,
        sources: [{
            src: videoLink,
            type: 'video/youtube'
        }]
    })

    useEffect(() => {
        if (response.isSuccess && response.data.sessions.length > 0) {
            setSelectedSessionTab(0)
        }
    }, [response.isSuccess])



    if (response.isLoading || getUser.isLoading || createSessionRatingRes.isLoading) return <Loading />
    if (response.isError) return <ApiErrorModal response={response} />

    const module = response.data;

    return (
        <div className='d-flex h-100'>
            {(createSessionRatingRes.isError) && <ApiErrorModal response={createSessionRatingRes} />}
            {(createSessionRatingRes.isSuccess) && <SuccessModal label={'Rate'} message={'Rating Submitted'} />}
            {(markReadRes.isSuccess) && <SuccessModal label={'Session'} message={'Marked As Read'} />}

            <div className='text-center bg-primary d-flex flex-column ' style={{ width: 350 }}>
                <div className='p-4'>
                    <img src={logo} alt="" className='rounded-4' style={{ width: 300 }} />
                </div>
                <h4 className='fw-bold bg-white p-4 text-primary rounded-top-5 mb-1 '>{module.name}</h4>
                <div className='bg-light flex-grow-1 overflow-y-auto'>
                    {module.sessions.map((item, index) =>
                        <div className={selectedSessionTab === index ? activeSessionTabClassName : inactiveSessionTabClassName}
                            onClick={() => setSelectedSessionTab(index)}>
                            <div className="d-flex justify-content-start gap-3">
                                <SessionReadButton module={module} selectedSessionTab={index} getUser={getUser} handleSessionRead={handleSessionRead} />
                                <div className="">   {item.name}</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className='d-flex b flex-column flex-grow-1 border-start border-primary border-2 h-100'>
                {
                    console.log("vvvvvvvv", module.sessions[selectedSessionTab]?.markAsReads.filter((item) => item.userId == getUser.data.id)[0])
                }
                <div className='d-flex bg-primary pt-4 pe-3 justify-content-between'>
                    <div className='bg-white pt-2 mb-1 rounded-top-4 px-4 fw-bold fs-5 text-primary'>Training Department</div>
                    <div className='d-flex'>

                        <div className="btn btn-light rounded-pill mb-3" onClick={() => navigate('/training')}>Back to training</div>


                    </div>
                </div>

                <div className='overflow-auto flex-grow-1'>
                    {
                        Number.isInteger(selectedSessionTab) &&

                        <div className='card h-100 w-100'>


                            <div className='  d-flex px-5 py-1' style={{ height: '35%' }}>
                                <VideoPlayer url={module.sessions[selectedSessionTab].videoLink} />
                            </div>


                            <div>
                         
                         <div className="d-flex gap-3 mb-2 bg-white justify-content-end pe-5">
                         <SessionReadButton isButton module={module} selectedSessionTab={selectedSessionTab} getUser={getUser} handleSessionRead={handleSessionRead} />
                         </div>

                                <div className='d-flex justify-content-between'>


                                    <div className={selectedTab === "Overview" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Overview")}>Overview</div>
                                    <div className={selectedTab === "Notes" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Notes")}>Notes</div>
                                    <div className={selectedTab === "QnA" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("QnA")}>QnA</div>
                                    <div className={selectedTab === "Announcement" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Announcement")}>Announcement</div>
                                    <div className={selectedTab === "Rating" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Rating")}>Rating</div>

                                </div>

                                <div className={selectedTab === "Overview" ? "d-block p-4" : "d-none"} dangerouslySetInnerHTML={{ __html: `${module.sessions[selectedSessionTab].overview}` }} style={{ height: '350px' }}></div>
                                <div className={selectedTab === "Notes" ? "d-block p-4" : "d-none"} dangerouslySetInnerHTML={{ __html: `${module.sessions[selectedSessionTab].notes}` }} style={{ height: '350px' }}></div>
                                <div className={selectedTab === "QnA" ? "d-block p-4" : "d-none"} dangerouslySetInnerHTML={{ __html: `${module.sessions[selectedSessionTab].queAns}` }} style={{ height: '350px' }}></div>
                                <div className={selectedTab === "Announcement" ? "d-block p-4" : "d-none"} dangerouslySetInnerHTML={{ __html: `${module.sessions[selectedSessionTab].announcement}` }} style={{ height: '350px' }}></div>
                                {/* <div className={selectedTab === "Rating" ? "d-block p-4" : "d-none"} dangerouslySetInnerHTML={{ __html: `${module.sessions[selectedSessionTab].rating}` }}></div> */}


                                <div className={selectedTab === "Rating" ? "d-block p-4 " : "d-none "} style={{ height: '350px' }} >

                                    <div className='d-flex justify-content-center align-items-center gap-5'>
                                        {
                                            // console.log("eeeeeee", module.sessions[selectedSessionTab].ratings.filter((item) => item.sessionId === id && userId ==getUser.data.id))
                                        }
                                        <img src={poster} className='w-25 rounded' />
                                        <div>
                                            <form onSubmit={handleRating} >
                                                <h3>Rate & Review</h3>
                                                <p>Let us know what do you feel about the training sessions.</p>
                                                <Rating
                                                    initialRating={rating}
                                                    fullSymbol={Icons.rating('display-6 text-warning', {})}
                                                    emptySymbol={Icons.ratingOutline('display-6 text-muted', {})}
                                                    onChange={setRating}
                                                />

                                                <h6 className='mb-1'>Your Feedback</h6>
                                                <div className="d-flex gap-2">
                                                    <Input name={'Feedback'} value={review} onChange={(e) => setReview(e.target.value)} />
                                                    <button className='btn btn-secondary'>Submit</button>

                                                </div>

                                            </form>
                                        </div>
                                    </div>



                                </div>
                            </div>



                        </div>

                    }
                </div>
            </div>

        </div >
    )
}

export default Sessions