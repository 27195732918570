import React from "react";
import Icons from "../../components/ui/Icons";
import pinWheelImg from '../../assets/images/pinWheel.png'

const PinWheel2 = () => {
  return (
    <>
      <div className="p-4 container-fluid h-100 hide-scroll">
        <div className="row shadow rounded-5 h-100" style={{ backgroundColor: "#EFFFFE", border: "10px solid #f3f3f2" }}>
          <div className="col-md-5">
            <div
              className=" w-100 d-flex flex-column rounded-5 align-items-center shadow overflow-hidden"
              style={{
                zIndex: 50,
                height: "100%",
                background: {pinWheelImg},
                border: "4px solid #86FFFA",
                backgroundSize: "cover",
                backgroundPosition: "center bottom",
                justifyContent: "flex-end",
              }}
            >
              <div
                className="d-block w-100 text-center p-3 py-4"
                style={{
                  background: "#F0FFFE",
                  border: "4px solid #86FFFA",
                  borderRadius: "32px",
                }}
              >
                <h2 style={{ fontWeight: 900, color: "#00A39D" }}>
                  The ICGS Pinwheel
                </h2>
              </div>
            </div>
          </div>

          <div className="col-md-7">
            <div className="d-flex flex-column align-items-center justify-content-center py-3  h-100">
              <div className="container d-flex gap-3 h-50  pb-3">
                <div className="row g-3 h-100">
                  <div className="col-md-6 ">
                    <div
                      className="card w-100 mb-3 shadow rounded-top-5 rounded-bottom-5 h-100"
                      style={{
                        borderBottomRightRadius: "20px !important",
                        borderBottomLeftRadius: "20px !important",
                      }}
                    >
                      <div
                        className="text-white fs-5 fw-bold p-2 rounded-pill d-flex justify-content-between align-items-center ps-3"
                        style={{ backgroundColor: "rgb(0, 101, 173)" }}
                      >
                        <p className="w-100 text-center">Vidhya</p>
                        <a
                          className="btn btn-outline-dark border-0 p-0 rounded-circle text-hover-primary"
                          href="/icgsPinwheel/vidhya"
                        >
                          {Icons.arrow()}

                        </a>
                      </div>
                      <div
                        className="m-4 overflow-y-scroll"
                        style={{ maxHeight: "230px" }}
                      >
                        <p className="fs-5">
                          Vidhya embodies modern education with three integral sections: Adhyayan for academic learning, Arogya for health education, and Kaushal for skill development, culminating in a comprehensive guide for contemporary education.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div
                      className="card w-100 mb-3 shadow rounded-top-5 rounded-bottom-5 h-100"
                      style={{
                        borderBottomRightRadius: "20px !important",
                        borderBottomLeftRadius: "20px !important",
                      }}
                    >
                      <div
                        className="text-white fs-5 fw-bold p-2 rounded-pill d-flex justify-content-between align-items-center ps-3"
                        style={{ backgroundColor: "rgb(229, 173, 0)" }}
                      >
                        <p className="w-100 text-center">Sadvidya</p>
                        <a
                          className="btn btn-outline-dark border-0 p-0 rounded-circle text-hover-primary"
                          href="/icgsPinwheel/sadvidhya"
                        >
                          {Icons.arrow()}

                        </a>
                      </div>
                      <div
                        className="m-4 overflow-y-scroll"
                        style={{ maxHeight: "230px" }}
                      >
                        <p className="fs-5">
                          Sadvidhya, denoting Social education, comprises three essential facets: Sanskar for etiquette, Sanskriti for tradition, and Vyaktitva Vikas for personality development, synergizing into a holistic guide for fostering social beings.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container d-flex gap-3 h-50 ">
                <div className="row h-100">
                  <div className="col-md-6">
                    <div
                      className="card w-100 mb-3 shadow rounded-top-5 rounded-bottom-5 h-100"
                      style={{
                        borderBottomRightRadius: "20px !important",
                        borderBottomLeftRadius: "20px !important",
                      }}
                    >
                      <div
                        className="text-white fs-5 fw-bold p-2 rounded-pill d-flex justify-content-between align-items-center ps-3"
                        style={{ backgroundColor: "rgb(229, 46, 183)" }}
                      >
                        <p className="w-100 text-center">Brahmvidya</p>
                        <a
                          className="btn btn-outline-dark border-0 p-0 rounded-circle text-hover-primary"
                          href="/icgsPinwheel/brahmvidhya"
                        >
                          {Icons.arrow()}
                        
                        </a>
                      </div>
                      <div
                        className="m-4 overflow-y-scroll"
                        style={{ maxHeight: "240px" }}
                      >
                        <p className="fs-5">
                          Brahmvidhya, signifying Spiritual education, encompasses three core elements: Satsang for spiritual companionship, Seva for societal service,and Samarpan for devoted community engagement, composing a unified guide for nurturing enlightened souls.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="card w-100 mb-3 shadow rounded-top-5 rounded-bottom-5 h-100"
                      style={{
                        borderBottomRightRadius: "20px !important",
                        borderBottomLeftRadius: "20px !important",
                      }}
                    >
                      <div
                        className="text-white fs-5 fw-bold p-2 rounded-pill d-flex justify-content-between align-items-center ps-3"
                        style={{ backgroundColor: "rgb(101, 155, 64)" }}
                      >
                        <p className="w-100 text-center">Charitram</p>
                        <a
                          className="btn btn-outline-dark border-0 p-0 rounded-circle text-hover-primary"
                          href="/icgsPinwheel/charitram"
                        >
                          {Icons.arrow()}
  
                        </a>
                      </div>
                      <div
                        className="m-4 overflow-y-scroll"
                        style={{ maxHeight: "240px" }}
                      >
                        <p className="fs-5">
                          Charitram, an auxiliary component of the pinwheel,embodies the practical manifestation from the lives of exemplary personalities, showcasing tangible results and real-world applications of the principles in Vidhya, Sadvidhya, and Brahmvidhya.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default PinWheel2;
