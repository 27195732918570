import React from "react";
import poster from "../../assets/images/course_poster.png";
import bookImg from "../../assets/images/book_poster.png";
import starImg from "../../assets/images/star.png";
import windmillImg from "../../assets/images/windmill.png";
import {
  useGetBooksQuery,
  useGetCategoriesQuery,
} from "../../services/userSlice";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../components/ui/Loading";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import Api from "../../constants/Api";
import Slider from "../../components/ui/Slider";

import icgsWheel from "../../assets/gif/icgsWheel.gif";
import Icons from "../../components/ui/Icons";
import Rating from "react-rating";

const CourseBooks = () => {
  const bookRes = useGetBooksQuery();
  const categoryRes = useGetCategoriesQuery();
  const navigate = useNavigate();

  if (categoryRes.isLoading || bookRes.isLoading || bookRes.isFetching)
    return <Loading />;
  if (categoryRes.isError) return <ApiErrorModal response={categoryRes} />;
  if (bookRes.isError) return <ApiErrorModal response={bookRes} />;

  const sortedCategories = categoryRes.isSuccess
    ? [...categoryRes.data].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
    : [];

  const sortedBooks = bookRes.isSuccess
    ? [...bookRes.data].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
    : [];

  return (
    <div className="container-fluid px-0 py-5 d-flex h-100 ">
      <div className="col-lg-9 px-5 overflow-hidden hide-scroll">
        <div className="overflow-auto h-100 ">
          <div className="row">
            <Slider type="CourseBooks" />
          </div>

          {categoryRes.isSuccess &&
            sortedCategories.map((item) => (
              <div key={item.id}>
                <div>
                  <div className="">
                    <h2 className="fw-bold text-black mt-5 mb-3  d-flex justify-content-between align-items-center">
                      <span>
                        Explore books for{" "}
                        <span className="text-danger me-3">{item.name}</span>{" "}
                      </span>
                      <span
                        className="btn btn-info text-white rounded-pill px-4 py-1"
                        onClick={() => navigate(`category/${item.id}`)}
                      >
                        View All
                      </span>
                    </h2>
                  </div>
                </div>
                <div className="row row-cols-3 g-4 mb-5">
                  {bookRes.isSuccess &&
                    sortedBooks
                      .filter((i) => i.categoryId === item.id)
                      .map((item2, index) => {
                        if (index < 3) {
                          let totalRating = 0;

                          item2.ratings.forEach((rating) => {
                            totalRating += rating.rating;
                          });

                          const averageRating =
                            totalRating / item2.ratings.length;

                          return (
                            <div key={item2.id} className="col ">
                              <div
                                className="d-flex p-3 h-100 rounded-4 overflow-hidden w-100 shadow border border-2 border-white"
                                style={{ backgroundColor: item2.color }}
                                onClick={() =>
                                  navigate("/coursebooks/" + item2.id)
                                }
                              >
                                {item2.images.length > 0 && (
                                  <img
                                    src={
                                      Api.IMAGE_URL +
                                      item2.images[0].id +
                                      item2.images[0].ext
                                    }
                                    alt=""
                                    className="me-3 rounded-3"
                                    width={150}
                                    height={200}
                                    style={{ objectFit: "cover" }}
                                  />
                                )}
                                <div className="d-flex flex-column justify-content-center overflow-hidden">
                                  <h6
                                    className="fw-bold  fs-5  mb-2"
                                    style={{}}
                                  >
                                    {item2.name}
                                  </h6>
                                  {/* <h6 className='fw-bold mb-2' style={{}}>Hath jhalya to lidhu ugari</h6> */}
                                  <p
                                    className="text-muted lh-sm mb-2"
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {item2.language}
                                  </p>
                                  {/* <p className='text-muted lh-sm mb-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>English</p> */}
                                  <p
                                    className="text-muted lh-sm mb-2"
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {item2.authorName}
                                  </p>
                                  {/* <p className='text-muted lh-sm mb-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Vivek Swami Ji</p> */}
                                  <p
                                    className="text-muted lh-sm mb-2"
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {item2.category.name}
                                  </p>
                                  {/* <p className='text-muted lh-sm mb-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Dharmic course</p> */}

                                  <Rating
                                    initialRating={averageRating}
                                    readonly
                                    fullSymbol={Icons.rating(
                                      "fs-5 text-warning  m-0",
                                      {}
                                    )}
                                    emptySymbol={Icons.rating("fs-5  m-0", {
                                      color: "#fafafa",
                                    })}
                                    // emptySymbol={Icons.ratingOutline('fs-5 text-white  m-0', {})}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return null; // Don't render anything for books beyond the first three
                        }
                      })}
                </div>
              </div>
            ))}
        </div>
      </div>

      <Link
        to={"/icgsPinwheel"}
        className="col-lg-3 bg-white overflow-hidden rounded-start-5 d-flex align-items-center"
      >
        <img className="text-center w-100 " src={icgsWheel} alt="" />
      </Link>
    </div>
  );
};

export default CourseBooks;
