const BRAHMVIDYA_URL = process.env.BRAHMVIDYA_FRONTEND || 'https://br.icgs.sgrsutility.org/'
const EDUCATION_URL = process.env.EDUCATION_FRONTEND || 'https://edu.icgs.sgrsutility.org/'
const SUPERADMIN_URL = process.env.SUPER_ADMIN_BACKEND || 'https://sa.sgrsutility.in/'
const BASE_URL = process.env.BRAHMVIDYA_BACKEND || 'https://br.icgs.sgrsutility.in/'

export default {
    BASE_URL,
    SUPERADMIN_URL,
    EDUCATION_URL,
    BRAHMVIDYA_URL,
    SUPERADMIN_USER_URL: SUPERADMIN_URL + "icgsBrahmvidya/user/",
    USER_URL: BASE_URL + "user/",
    SLIDER_URL: BASE_URL + "uploads/sliderImages/",
    SUBMIT_IMAGES: "uploads/submitImages/",
    IMAGE_URL: BASE_URL + "uploads/images/",
    PROFILE_PICTURE_URL: SUPERADMIN_URL + "uploads/images/profilePictures/"
}