import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useGetNoticeByIdQuery } from '../../services/userSlice'

const ViewNoticeModal = ({ id }) => {

    const modalId = "viewNoticeModal" + id
    const navigate = useNavigate()
    const noticeRes = useGetNoticeByIdQuery(id)

    return (
        <>
            <Link type="button" className="text-danger fw-bold" data-bs-toggle="modal" data-bs-target={"#" + modalId}>
                See Now
            </Link>

            <div className="modal fade" id={modalId} tabindex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id={modalId + "Label"}>{noticeRes.data?.title}</h1>
                            {/* <button id={modalId + "CloseBtn"} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        </div>
                        <div className="modal-body">
                            <div dangerouslySetInnerHTML={{ __html: noticeRes.data?.content }}></div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary text-white" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewNoticeModal