import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAbout: builder.query({ query: () => `aboutSection`, keepUnusedDataFor: 0 }),
        getAboutById: builder.query({ query: (id) => `aboutSection?id=${id}`, keepUnusedDataFor: 0 }),
        
    })
})

export const {
    useGetAboutQuery,
    useGetAboutByIdQuery,
   
} = extendedApiSlice
