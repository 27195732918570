import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Loading from '../../components/ui/Loading'
import ApiErrorModal from '../../components/modals/ApiErrorModal'
import { useGetTutorialByIdQuery, useGetUserDetailsQuery, useMarkTutotrialReadMutation, useMarkTutotrialUnReadMutation } from '../../services/userSlice'
import EditSuggestionModal from '../../components/modals/EditSuggestionModal'
import EditTutorialMarkedRead from '../../components/modals/EditTutorialMarkedRead'
import SuccessModal from '../../components/modals/SuccessModal'
import Icons from '../../components/ui/Icons'
import Rating from 'react-rating'
import Select from '../../components/form/Select'

const Tutorial = () => {

    const { id } = useParams()
    const tutorialRes = useGetTutorialByIdQuery(id)
    const userRes = useGetUserDetailsQuery(id)
    const [markRead, markReadRes] = useMarkTutotrialReadMutation();
    const [markUnRead, markUnReadRes] = useMarkTutotrialUnReadMutation();
    const [selectedLanguage, setSelectedLanguage] = useState("ENGLISH");

    const [selectedTab, setSelectedTab] = useState("Overview");
    const activeTabClassName = "flex-grow-1 bg-light py-4 text-center border-bottom border-5 border-danger fw-bold";
    const inactiveTabClassName = "flex-grow-1 bg-primary py-4 text-center text-white";

    const handleMarkUnread = (id) => {
        let body = {
            tutorialId: id
        }
        markUnRead(body)
            .then(() => tutorialRes.refetch())
    }

    if (tutorialRes.isLoading || userRes.isLoading || markUnReadRes.isLoading) return <Loading />

    if (tutorialRes.isError) return <ApiErrorModal response={tutorialRes} />
    if (markReadRes.isError) return <ApiErrorModal response={markReadRes} />


    const calculateAverageRating = (ratings) => {
        if (!ratings || ratings.length === 0) return 0;
        const totalRating = ratings.reduce((acc, rating) => acc + rating.rating, 0);
        return totalRating / ratings.length;
    };

    const averageRating = calculateAverageRating(tutorialRes.data.markAsReads.map(mark => mark.rating));

    return (
        <div className='px-5 pt-5'>

            {
                markReadRes.isSuccess && <SuccessModal label={'Rate'} message={'Marked As Complete'} />
            }


            <div className="d-flex justify-content-between">

                <div className=' '>
                    <h5 className='fw-bold text-danger'>{tutorialRes.data.title}</h5>
                    <p className='fs-13 text-muted'>{`${tutorialRes.data.section} > ${tutorialRes.data.books} > ${tutorialRes.data.topic}`}</p>

                    <Rating
                        initialRating={averageRating}
                        readonly
                        fullSymbol={Icons.rating('fs-5 text-warning m-0', {})}
                        emptySymbol={Icons.ratingOutline('fs-5 text-muted m-0', {})}
                    />

                </div>

                <div className='d-flex gap-2'>
                    <Select
                        inputClass={'bg-white mx-2 mt-1 h-50 w-100'}
                        options={[
                            'ENGLISH', 'HINDI', 'GUJRATI'
                        ]}
                        value={selectedLanguage}
                        onChange={(e) => setSelectedLanguage(e.target.value)}
                        required
                    />
                    <div className="d-flex mt-4  justify-content-end mb-3 gap-2">


                        <EditSuggestionModal id={tutorialRes.data.id} />
                        {
                            (tutorialRes.data.markAsReads.filter(item => (item.userId == userRes.data.id && item.tutorialId == id)).length <= 0) ?
                                <EditTutorialMarkedRead id={tutorialRes.data.id} markRead={markRead} markReadRes={markReadRes} refetch={tutorialRes.refetch} />
                                :
                                <button className='btn fw-bold px-4 py-2 rounded-pill border border-white border-3' onClick={() => handleMarkUnread(tutorialRes.data.id)} style={{ backgroundColor: '#1EC624' }} > Marked As UnRead</button>


                        }

                        <Link to={'/support'} className='btn  fw-bold px-4 py-2 rounded-pill border border-white border-3' style={{ backgroundColor: '#2395FF' }} >Need Help? Create a ticket</Link>
                    </div>
                </div>
            </div>

            <div className='card mt-2 rounded-5 overflow-hidden shadow'>
                <div className="d-flex justify-content-between">
                    <div className={selectedTab === "Overview" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Overview")}>Overview</div>
                    <div className={selectedTab === "Tutorial" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Tutorial")}>Tutorial</div>
                    <div className={selectedTab === "ICGS Manual" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("ICGS Manual")}>ICGS Manual</div>
                    <div className={selectedTab === "Activity" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Activity")}>Activity</div>
                    <div className={selectedTab === "Material" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Material")}>Material</div>
                    {/* <div className={selectedTab === "Suggestions" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Suggestions")}>Suggestions</div> */}
                    {/* <div className={selectedTab === "Assignment" ? activeTabClassName : inactiveTabClassName} onClick={() => setSelectedTab("Assignment")}>Assignment</div> */}
                </div>

                <div className={(selectedTab === "Overview" && selectedLanguage === 'ENGLISH') ? "d-block p-4" : "d-none"} style={{ height: '430px', overflow: 'auto' }}> <div dangerouslySetInnerHTML={{ __html: tutorialRes.data?.overview }}></div></div>
                <div className={(selectedTab === "Tutorial" && selectedLanguage === 'ENGLISH') ? "d-block p-4" : "d-none"} style={{ height: '430px', overflow: 'auto' }}> <div dangerouslySetInnerHTML={{ __html: tutorialRes.data?.tutorial }}></div></div>
                <div className={(selectedTab === "ICGS Manual" && selectedLanguage === 'ENGLISH') ? "d-block p-4" : "d-none"} style={{ height: '430px', overflow: 'auto' }}> <div dangerouslySetInnerHTML={{ __html: tutorialRes.data?.manual }}></div></div>
                <div className={(selectedTab === "Activity" && selectedLanguage === 'ENGLISH') ? "d-block p-4" : "d-none"} style={{ height: '430px', overflow: 'auto' }}> <div dangerouslySetInnerHTML={{ __html: tutorialRes.data?.activity }}></div></div>
                <div className={(selectedTab === "Material" && selectedLanguage === 'ENGLISH') ? "d-block p-4" : "d-none"} style={{ height: '430px', overflow: 'auto' }}> <div dangerouslySetInnerHTML={{ __html: tutorialRes.data?.material }}></div></div>


                <div className={(selectedTab === "Overview" && selectedLanguage === 'HINDI') ? "d-block p-4" : "d-none"} style={{ height: '430px', overflow: 'auto' }}> <div dangerouslySetInnerHTML={{ __html: tutorialRes.data?.overviewHindi }}></div></div>
                <div className={(selectedTab === "Tutorial" && selectedLanguage === 'HINDI') ? "d-block p-4" : "d-none"} style={{ height: '430px', overflow: 'auto' }}> <div dangerouslySetInnerHTML={{ __html: tutorialRes.data?.tutorialHindi }}></div></div>
                <div className={(selectedTab === "ICGS Manual" && selectedLanguage === 'HINDI') ? "d-block p-4" : "d-none"} style={{ height: '430px', overflow: 'auto' }}> <div dangerouslySetInnerHTML={{ __html: tutorialRes.data?.manualHindi }}></div></div>
                <div className={(selectedTab === "Activity" && selectedLanguage === 'HINDI') ? "d-block p-4" : "d-none"} style={{ height: '430px', overflow: 'auto' }}> <div dangerouslySetInnerHTML={{ __html: tutorialRes.data?.activityHindi }}></div></div>
                <div className={(selectedTab === "Material" && selectedLanguage === 'HINDI') ? "d-block p-4" : "d-none"} style={{ height: '430px', overflow: 'auto' }}> <div dangerouslySetInnerHTML={{ __html: tutorialRes.data?.materialHindi }}></div></div>


                <div className={(selectedTab === "Overview" && selectedLanguage === 'GUJRATI') ? "d-block p-4" : "d-none"} style={{ height: '430px', overflow: 'auto' }}> <div dangerouslySetInnerHTML={{ __html: tutorialRes.data?.overviewGujrati }}></div></div>
                <div className={(selectedTab === "Tutorial" && selectedLanguage === 'GUJRATI') ? "d-block p-4" : "d-none"} style={{ height: '430px', overflow: 'auto' }}> <div dangerouslySetInnerHTML={{ __html: tutorialRes.data?.tutorialGujrati }}></div></div>
                <div className={(selectedTab === "ICGS Manual" && selectedLanguage === 'GUJRATI') ? "d-block p-4" : "d-none"} style={{ height: '430px', overflow: 'auto' }}> <div dangerouslySetInnerHTML={{ __html: tutorialRes.data?.manualGujrati }}></div></div>
                <div className={(selectedTab === "Activity" && selectedLanguage === 'GUJRATI') ? "d-block p-4" : "d-none"} style={{ height: '430px', overflow: 'auto' }}> <div dangerouslySetInnerHTML={{ __html: tutorialRes.data?.activityGujrati }}></div></div>
                <div className={(selectedTab === "Material" && selectedLanguage === 'GUJRATI') ? "d-block p-4" : "d-none"} style={{ height: '430px', overflow: 'auto' }}> <div dangerouslySetInnerHTML={{ __html: tutorialRes.data?.materialGujrati }}></div></div>



                {/* <div className={selectedTab === "Suggestions" ? "d-block p-4" : "d-none"}>Test</div> */}
                {/* <div className={selectedTab === "Assignment" ? "d-block p-4" : "d-none"}> <div dangerouslySetInnerHTML={{ __html: tutorialRes.data?.assignment }}></div></div> */}
            </div>

        </div>
    )
}

export default Tutorial