import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Rating from 'react-rating';
import Icons from '../ui/Icons';
import FormattedInput from '../form/FormattedInput';
import Input from '../form/Input';
import Slider from '../ui/Slider';
import UploadImage from '../ui/UploadImage';
import NewUploadImage from '../ui/NewUploadImage';

const EditTutorialMarkedRead = ({ id, type, markRead, markReadRes, refetch }) => {

    const modalId = "editTutorialMarkedRead" + id;
    // const formattedInputName = "formattedInputName" + id;
    const [rating, setRating] = useState(0)

    const [images, setImages] = useState([])


    // const handlePublish = async (e) => {
    //     e.preventDefault();


    //     let body = new FormData();

    //     body.append('content', editorContent);
    //     if (selectedImage) {
    //         body.append('image', selectedImage);
    //     }

    //     // You may want to await the mutation call
    //     const res = await createSuggestion({ id, body });

    //     if (!res.error) {
    //         // Handle success, refetch, or any other action
    //     }
    // };

    // tutorialId, rating, review, ansOne, ansTwo, ansThree


    const handleSubmit = (e) => {
        e.preventDefault();

        // const images = e.target['images']?.files || [];

        const formData = new FormData();
        formData.append('rating', rating);
        formData.append('review', ''); // Make sure empty string for review is intended
        formData.append('tutorialId', id);

        formData.append('ansOne', e.target['ansOne']?.value || '');
        formData.append('ansTwo', e.target['ansTwo']?.value || '');
        formData.append('ansThree', e.target['ansThree']?.value || '');

        images.forEach((image, index) => {
            formData.append(`images`, image.file); 
        });


        markRead(formData)
            .then(() => refetch())
            .catch(error => console.error('Error:', error));
    }

    return (
        <>
            <Link
                type="button"
                className='btn fw-bold px-4 py-2 rounded-pill border border-white border-3'
                style={{ backgroundColor: '#EEB217' }}
                data-bs-toggle="modal"
                data-bs-target={"#" + modalId}
            >
                Mark As Read
            </Link>

            <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content rounded-4">
                        <div className="modal-header d-flex justify-content-center p-0">
                            <h1 className="modal-title fs-5 bg-primary text-white px-3 py-1 rounded-bottom-4 mb-3" id={modalId + "Label"}>Marked as Complete</h1>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">

                                <div className='text-center'>
                                    <h3 className='fw-bold'>Rate This <span className='text-danger'>Tutorial</span></h3>
                                    <p>Let us know what do you feel about the book you just
                                        completed.</p>

                                    <>
                                        <div className="container py-5">
                                            <div className="row mb-3">
                                                <div className="col text-center">
                                                    <Rating initialRating={rating} fullSymbol={Icons.rating(' fs-3 text-warning', {})}
                                                        emptySymbol={Icons.ratingOutline('fs-3 text-muted', {})} onChange={setRating} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col text-center">

                                            </div>
                                        </div>
                                    </>



                                </div>
                                <div>
                                    <h4 className='fw-bold'>Answer the following qustions</h4>
                                    <p>Q1. Question 1.</p>
                                    <Input name={"ansOne"} labelName={"Ans."}  />

                                    <p>Q2. Question 2.</p>
                                    <Input name={"ansTwo"} labelName={"Ans."}  />

                                    <p>Q3. Question 3.</p>
                                    <Input name={"ansThree"} labelName={"Ans."}  />

                                </div>


                                {/* <input type="file" className="form-control mt-3" id="images" name="images" multiple accept="image/*" /> */}
                                {/* <Slider type={""} /> */}
                               <NewUploadImage images={images} setImages={setImages}  />
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary text-white rounded-pill px-4" >Save</button>
                                <button type="button" className="btn btn-primary text-white rounded-pill px-4" data-bs-dismiss="modal">close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditTutorialMarkedRead;
