import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../redux/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDownloadables: builder.query({ query: () => `downlodables`, keepUnusedDataFor: 0 }),
        // createDownloadable: builder.mutation({ query: (body) => ({ url: `downlodables`, method: 'POST', body }) }),
    })
})

export const { useGetDownloadablesQuery, useCreateDownloadableMutation } = extendedApiSlice
