import React from "react";
import Icons from "../../components/ui/Icons";
import {
  useGetTechSupportQuery,
  useGetTutorialsQuery,
  useGetUserDetailsQuery,
} from "../../services/userSlice";
import Loading from "../../components/ui/Loading";
import ApiErrorModal from "../../components/modals/ApiErrorModal";
import { Link, useNavigate } from "react-router-dom";
import Slider from "../../components/ui/Slider";
import Api from "../../constants/Api";
import { useGetBranchesQuery } from "../../services/branchSlice";
import Rating from "react-rating";
import Image from "../../components/ui/Image";

const SandhyaSabha = () => {
  const navigate = useNavigate();
  const response = useGetTutorialsQuery();
  const userRes = useGetUserDetailsQuery();
  const branchRes = useGetBranchesQuery();
  const techInfo = useGetTechSupportQuery();

  const currentDate = new Date();

  let maxMarkAsReadLength = 0;

  if (
    response.isLoading ||
    userRes.isLoading ||
    branchRes.isLoading ||
    techInfo.isLoading
  )
    return <Loading />;
  if (response.isError) return <ApiErrorModal response={response} />;
  if (userRes.isError) return <ApiErrorModal response={userRes} />;
  if (branchRes.isError) return <ApiErrorModal response={branchRes} />;
  if (techInfo.isError) return <ApiErrorModal response={techInfo} />;

  const releasedTutorials = response.data.filter((item) => {
    const releaseDate = new Date(item.releaseDate);
    return item.publishInUserApp && releaseDate <= currentDate;
  });

  userRes?.data?.branch?.users?.map((item) => {
    const userMarkAsReadLength = response?.data?.reduce((len, tut) => {
      return (
        len +
        tut.markAsReads?.filter(
          (markAsReadItem) =>
            markAsReadItem.userId == item.id && markAsReadItem.isRead
        ).length
      );
    }, 0);

    maxMarkAsReadLength = Math.max(maxMarkAsReadLength, userMarkAsReadLength);
  });

  const calculateTutorials = (branchId, users) => {
    const taughtTutorials = releasedTutorials?.filter((tutorial) => {
      return users.some((user) =>
        tutorial.markAsReads.some((read) => read.userId === user.id)
      );
    });

    const untaughtTutorials = releasedTutorials?.filter((tutorial) => {
      return !users.some((user) =>
        tutorial.markAsReads.some((read) => read.userId === user.id)
      );
    });

    return {
      taughtTutorials: taughtTutorials?.length || 0,
      untaughtTutorials: untaughtTutorials?.length || 0,
    };
  };

  const branchPerformances = branchRes?.data?.map((data) => {
    const tutorials = calculateTutorials(data.id, data.users);
    return {
      branchId: data.id,
      branchName: data.branchName,
      mainBranch: data.mainBranch,
      status: data.status,
      totalTutors: data.users.length,
      taughtTutorials: tutorials.taughtTutorials,
      untaughtTutorials: tutorials.untaughtTutorials,
    };
  });

  const sortedBranches = branchPerformances?.sort(
    (a, b) => b.taughtTutorials - a.taughtTutorials
  );

  const currentBranch = sortedBranches?.find(
    (item) => item.branchId == userRes?.data?.branch.id
  );

  console.log("ew", maxMarkAsReadLength);

  // const progressPercentage = (maxMarkAsReadLength / releasedTutorials?.length) * 100;

  const progressPercentage =
    (maxMarkAsReadLength / (releasedTutorials?.length || 1)) * 100;

  const calculateAverageRating = (ratings) => {
    if (!ratings || ratings.length === 0) return 0;
    const totalRating = ratings.reduce((acc, rating) => acc + rating.rating, 0);
    return totalRating / ratings.length;
  };
 
  return (
    <div className="container-fluid py-4 d-flex h-100 overflow-hidden hide-scroll flex-wrap">
      <div className="row">
        <div className="col-lg-9 px-5 overflow-auto h-100">
          <Slider type="SandhyaSabha" />

          <h2 className="fw-bold text-danger mt-4 mb-1">
            Tutorials <span className="text-black">This Week</span>
          </h2>
          <p className="mb-4">
            This page offers a quick view of all your assignments, making it
            easy to stay organized and meet deadlines.
          </p>

          <div className="  ">
            {response.isSuccess &&
              response?.data
                ?.filter((item) => item.publishInUserApp === true)
                .map((item) => {
                  const averageRating = calculateAverageRating(
                    item.markAsReads.map((mark) => mark.rating)
                  );

                  return (
                    <div
                      key={item.id}
                      className="d-flex flex-wrap align-items-center justify-content-center bg-white rounded-4 my-3 shadow mx-0 row "
                    >
                      <div className="p-3 border-end col-2 ">
                        <h6 className="fw-bold">Date</h6>
                        <p className="fs-13 text-muted">
                          {new Date(item.releaseDate).toLocaleDateString()}
                        </p>
                      </div>

                      <div className="p-3 border-end   flex-grow-1 col-3">
                        <h6 className="fw-bold">
                          {item.title.length > 10
                            ? item.title.substring(0, 9) + "..."
                            : item.title}
                        </h6>
                        <p className="fs-13 text-muted">{`${item.section} > ${item.books} > ${item.topic}`}</p>
                      </div>

                      <div className="p-3 border-end col-2">
                        <h6 className="fw-bold">
                          {item.avgCompletionTime.includes("hr") ||
                          item.avgCompletionTime === ""
                            ? item.avgCompletionTime
                            : item.avgCompletionTime + " hr"}
                        </h6>
                        <p className="fs-13 text-muted">Average Time</p>
                      </div>

                      <div className="p-3 border-end col-2">
                        <p className="fs-13 text-muted">Ratings</p>
                        <Rating
                          initialRating={averageRating}
                          readonly
                          fullSymbol={Icons.rating("fs-6 text-warning m-0", {})}
                          emptySymbol={Icons.ratingOutline(
                            "fs-6 text-muted m-0",
                            {}
                          )}
                        />
                      </div>

                      <div className="p-3 border-end col-2 text-center d-flex justify-content-center">
                        {item.markAsReads.filter(
                          (item2) =>
                            item2.tutorialId == item.id &&
                            item2.userId == userRes.data.id
                        ).length > 0 ? (
                          <p className="fs-13 text-white rounded-pill bg-success text-center py-1 px-3 w-50">
                            Done
                          </p>
                        ) : (
                          <p className="fs-13 text-white rounded-pill bg-danger text-center py-1 px-3 w-50">
                            Pending
                          </p>
                        )}
                      </div>

                      <div className="p-3 col-1 ">
                        <div
                          className="btn btn-outline-success border-0 p-0 rounded-circle text-hover-white "
                          onClick={() => navigate("tutorial/" + item.id)}
                        >
                          {Icons.arrow()}
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>

        <div className="col-lg-3 bg-white rounded-start-5 d-flex flex-column align-items-center h-100">
          <div className=" w-100 d-flex justify-content-center p-3">
            {/* <img
            src={Api.SUPERADMIN_URL + `uploads/images/profilePictures/${userRes?.data?.branch?.profilePicture?.id}${userRes?.data?.branch?.profilePicture?.ext}`}
            className='rounded-circle border'
            alt=""
            style={{
              width: 280,
              height: 280,
              objectFit: 'fill', // Add this line
            }}
          /> */}

            <Image
              src={
                Api.SUPERADMIN_URL +
                `uploads/images/profilePictures/${userRes?.data?.branch?.profilePicture?.id}${userRes?.data?.branch?.profilePicture?.ext}`
              }
              alt="profile"
              className="rounded-circle "
              style={{
                width: 240,
                height: 240,
                objectFit: "contain", // Add this line
              }}
              icon={() =>
                Icons.defaultUser("", { width: "100%", height: "100%" })
              }
            />
          </div>

          <div className="fs-4">
            {userRes.data.branch.branchName}: {userRes?.data?.branch.address}
          </div>
          {/* <div className='fw-bold fs-4'>Gurukul Vadodara Gujarat</div> */}

          <div className="mb-3 fs-5">GUID: {userRes.data.branch.id}</div>

          <div
            className="text-danger fw-bold mb-4 fs-5"
            dangerouslySetInnerHTML={{ __html: techInfo.data[0].sandhyaTitle }}
          ></div>

          <div className="fs-10 text-start mb-1 mx-4 w-75">
            Branch Progress is at{" "}
            <span className="fw-bold">
              {isFinite(progressPercentage)
                ? Math.floor(progressPercentage)
                : 0}
              %
            </span>
          </div>

          <div className="progress w-75 mx-4 shadow">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated bg-success"
              role="progressbar"
              style={{ width: `${progressPercentage}%` }}
            ></div>
          </div>

          <div className="px-3 pt-3 mt-3 container">
            <div className="row row-cols-2 g-3 px-3">
              <div className="col">
                <div className=" d-flex flex-column text-center shadow p-4 fs-13 h-100  overflow-hidden">
                  <div className=" fs-5">{releasedTutorials?.length}</div>
                  <div className="fs-6">Tutorials Released</div>
                </div>
                <div
                  className="bg-info"
                  style={{ marginTop: -10, height: 11 }}
                ></div>
              </div>
              <div className="col">
                <div className="bg-white text-center shadow p-4 h-100 fs-13 ">
                  <div className="fs-5">{currentBranch.taughtTutorials}</div>
                  <div className="fs-6">
                    Tutorials <br />
                    Taught
                  </div>
                </div>
                <div
                  className="bg-warning"
                  style={{ marginTop: -10, height: 11 }}
                ></div>
              </div>
              <div className="col">
                <div className="bg-white text-center shadow p-4 fs-13  h-100 ">
                  <div className="fs-5">
                    {releasedTutorials?.length - currentBranch.taughtTutorials}
                  </div>
                  <div className="fs-6 ">
                    Tutorial <br /> Left
                  </div>
                </div>
                <div
                  className="bg-success"
                  style={{ marginTop: -10, height: 11 }}
                ></div>
              </div>

              <div className="col">
                <div className="bg-white text-center shadow p-4 fs-13 h-100">
                  <div className="fs-5">{currentBranch.totalTutors}</div>
                  <div className="fs-6">Registered Tutors</div>
                </div>
                <div
                  className="bg-danger"
                  style={{ marginTop: -10, height: 11 }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className=" py-4 d-flex h-100 overflow-hidden hide-scroll   flex-wrap">
      <div className="w-75 px-5 overflow-auto h-100">
        {/* 
        {
          console.log("eee", response?.data)
        } */}

        {/* <div className="row">
        </div> */}
        <Slider type="SandhyaSabha" />

        <h2 className="fw-bold text-danger mt-4 mb-1">
          Tutorials <span className="text-black">This Week</span>
        </h2>
        <p className="mb-4">
          This page offers a quick view of all your assignments, making it easy
          to stay organized and meet deadlines.
        </p>

        <div className="  ">
          {response.isSuccess &&
            response?.data
              ?.filter((item) => item.publishInUserApp === true)
              .map((item) => {
                const averageRating = calculateAverageRating(
                  item.markAsReads.map((mark) => mark.rating)
                );

                return (
                  <div
                    key={item.id}
                    className="d-flex flex-wrap align-items-center justify-content-center bg-white rounded-4 my-3 shadow mx-0 row "
                  >
                    <div className="p-3 border-end col-2 ">
                      <h6 className="fw-bold">Date</h6>
                      <p className="fs-13 text-muted">
                        {new Date(item.releaseDate).toLocaleDateString()}
                      </p>
                    </div>

                    <div className="p-3 border-end   flex-grow-1 col-3">
                      <h6 className="fw-bold">
                        {item.title.length > 10
                          ? item.title.substring(0, 9) + "..."
                          : item.title}
                      </h6>
                      <p className="fs-13 text-muted">{`${item.section} > ${item.books} > ${item.topic}`}</p>
                    </div>

                    <div className="p-3 border-end col-2">
                      <h6 className="fw-bold">
                        {item.avgCompletionTime.includes("hr") ||
                        item.avgCompletionTime === ""
                          ? item.avgCompletionTime
                          : item.avgCompletionTime + " hr"}
                      </h6>
                      <p className="fs-13 text-muted">Average Time</p>
                    </div>

                    <div className="p-3 border-end col-2">
                      <p className="fs-13 text-muted">Ratings</p>
                      <Rating
                        initialRating={averageRating}
                        readonly
                        fullSymbol={Icons.rating("fs-6 text-warning m-0", {})}
                        emptySymbol={Icons.ratingOutline(
                          "fs-6 text-muted m-0",
                          {}
                        )}
                      />
                    </div>

                    <div className="p-3 border-end col-2 text-center d-flex justify-content-center">
                      {item.markAsReads.filter(
                        (item2) =>
                          item2.tutorialId == item.id &&
                          item2.userId == userRes.data.id
                      ).length > 0 ? (
                        <p className="fs-13 text-white rounded-pill bg-success text-center py-1 px-3 w-50">
                          Done
                        </p>
                      ) : (
                        <p className="fs-13 text-white rounded-pill bg-danger text-center py-1 px-3 w-50">
                          Pending
                        </p>
                      )}
                    </div>

                    <div className="p-3 col-1 ">
                      <div
                        className="btn btn-outline-success border-0 p-0 rounded-circle text-hover-white "
                        onClick={() => navigate("tutorial/" + item.id)}
                      >
                        {Icons.arrow()}
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>

      <div className="w-25  bg-white rounded-start-5 d-flex flex-column align-items-center h-100">
        <div className=" w-100 d-flex justify-content-center p-3">
          {/* <img
            src={Api.SUPERADMIN_URL + `uploads/images/profilePictures/${userRes?.data?.branch?.profilePicture?.id}${userRes?.data?.branch?.profilePicture?.ext}`}
            className='rounded-circle border'
            alt=""
            style={{
              width: 280,
              height: 280,
              objectFit: 'fill', // Add this line
            }}
          /> */}

          <Image
            src={
              Api.SUPERADMIN_URL +
              `uploads/images/profilePictures/${userRes?.data?.branch?.profilePicture?.id}${userRes?.data?.branch?.profilePicture?.ext}`
            }
            alt="profile"
            className="rounded-circle "
            style={{
              width: 240,
              height: 240,
              objectFit: "fill", // Add this line
            }}
            icon={() =>
              Icons.defaultUser("", { width: "100%", height: "100%" })
            }
          />
        </div>

        <div className="fs-4">
          {userRes.data.branch.branchName}: {userRes?.data?.branch.address}
        </div>
        {/* <div className='fw-bold fs-4'>Gurukul Vadodara Gujarat</div> */}

        <div className="mb-3 fs-5">GUID: {userRes.data.branch.id}</div>

        <div
          className="text-danger fw-bold mb-4 fs-5"
          dangerouslySetInnerHTML={{ __html: techInfo.data[0].sandhyaTitle }}
        ></div>

        <div className="fs-10 text-start mb-1 mx-4 w-75">
          Branch Progress is at{" "}
          <span className="fw-bold">
            {isFinite(progressPercentage) ? Math.floor(progressPercentage) : 0}%
          </span>
        </div>

        <div className="progress w-75 mx-4 shadow">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated bg-success"
            role="progressbar"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>

        <div className="px-3 pt-3 mt-3 container">
          <div className="row row-cols-2 g-3 px-3">
            <div className="col">
              <div className=" d-flex flex-column text-center shadow p-4 fs-13 h-100  overflow-hidden">
                <div className=" fs-5">{releasedTutorials?.length}</div>
                <div className="fs-6">Tutorials Released</div>
              </div>
              <div
                className="bg-info"
                style={{ marginTop: -10, height: 11 }}
              ></div>
            </div>
            <div className="col">
              <div className="bg-white text-center shadow p-4 h-100 fs-13 ">
                <div className="fs-5">{currentBranch.taughtTutorials}</div>
                <div className="fs-6">
                  Tutorials <br />
                  Taught
                </div>
              </div>
              <div
                className="bg-warning"
                style={{ marginTop: -10, height: 11 }}
              ></div>
            </div>
            <div className="col">
              <div className="bg-white text-center shadow p-4 fs-13  h-100 ">
                <div className="fs-5">
                  {releasedTutorials?.length - currentBranch.taughtTutorials}
                </div>
                <div className="fs-6 ">
                  Tutorial <br /> Left
                </div>
              </div>
              <div
                className="bg-success"
                style={{ marginTop: -10, height: 11 }}
              ></div>
            </div>

            <div className="col">
              <div className="bg-white text-center shadow p-4 fs-13 h-100">
                <div className="fs-5">{currentBranch.totalTutors}</div>
                <div className="fs-6">Registered Tutors</div>
              </div>
              <div
                className="bg-danger"
                style={{ marginTop: -10, height: 11 }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SandhyaSabha;
