
import React from 'react'
import Icons from './Icons';

const ModuleDoneLabel = ({module}) => {
    let totalSessions = 0;
    let completedSessions = 0;

    module.sessions.forEach(session => {
        totalSessions++;

        if (session.markAsReads && session.markAsReads.some(markAsRead => markAsRead.isRead)) {
            completedSessions++;
        }
    });

    const allSessionsCompleted = totalSessions > 0 && completedSessions === totalSessions;

    return (
        <div>
            {allSessionsCompleted && <span className=''>{Icons.check('fs-2 text-success')}</span>}
        </div>
    );
}

export default ModuleDoneLabel
