import React from 'react'
import { sideBarLinks } from './Sidebar'
import { NavLink } from 'react-router-dom'
import logoImg from "../../assets/images/logo.png"
import Icons from '../ui/Icons';

const SidebarOffCanvas = () => {

    const navItem = (link) => {
        return (
            <li key={link.text} className="nav-item">
                <NavLink to={link.to} className="nav-link d-flex align-items-center" onClick={()=>{document.getElementById("sidebarOffCanvasButton").click()}}>
                    {link.icon("me-2", { width: 20, height: 20 })}
                    {link.text}
                </NavLink>
            </li>
        )
    }


    return (
        <>

            <div className="offcanvas offcanvas-start w-100" tabindex="-1" id="sidebarOffCanvas" aria-labelledby="sidebarOffCanvasLabel">


                <div className="d-flex flex-column flex-shrink-0 bg-primary h-100 w-100 shadow" >

                    <div className='d-flex mb-3'>
                       
                       
                    <div className='d-flex justify-content-center flex-grow-1'>
                        <div className="bg-white rounded-bottom-5 p-3" id="credManagerModalButton" data-bs-toggle="modal" data-bs-target="#credManagerModal">
                            <img src={logoImg} alt="" style={{ width: 150 }} />
                        </div>



                    </div>


                    <button id='sidebarOffCanvasButton' type="button" className="btn-close btn-close-white mt-3 me-3" data-bs-dismiss="offcanvas" aria-label="Close"></button>

                    </div>

                    


                    <div className='overflow-auto py-3'>
                        <ul className="nav nav-pills flex-column mb-auto px-3">
                            {sideBarLinks.map(navItem)}
                        </ul>
                    </div>

                </div>
            </div>
        </>
    )
}

export default SidebarOffCanvas