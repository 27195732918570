import React from 'react';

const VideoPlayer = ({ url }) => {

    
    const isYouTubeUrl = (url) => {
        return /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm.test(url);
    };


const extractYouTubeVideoId = (url) => {
    const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([^&\n?#]+)/);
    if (match && match[1]) {
        return match[1];
    }
    return null;
};


    if (isYouTubeUrl(url)) {
        const videoId = extractYouTubeVideoId(url);  

        console.log("videoId",videoId)
        return (
            <iframe
                title="YouTube video player"
                width="700"
                height="380"
                src={`https://www.youtube.com/embed/${videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        );
    } else {
        return <video controls controlsList="nodownload" src={url} style={{width:700,height:380}} />;

        
    }
};

export default VideoPlayer;

