import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Rating from 'react-rating'
import Icons from '../ui/Icons'

const CreateRatingModal = ({ id, type, submit, submitResponse, resBook, reRate }) => {

    const modalId = "editAboutModal" + id

    const [editorContent, setEditorContent] = useState("");
    const [rating, setRating] = useState(0)

    const [review, setReview] = useState('')


    const onSubmit = () => {



        let body = {
            "ratingValue": rating,
            "review": review
        }

        submit({ id, body })
            .then(() => resBook.refetch())
    }


    // if (aboutRes.isLoading) return <Loading />
    // if (aboutRes.isError) return <div>Something went wrong...</div>

    return (
        <>
            <Link type="button" className="btn btn-primary text-white rounded-3 px-3 mx-2" data-bs-toggle="modal" data-bs-target={"#" + modalId}>
                {reRate ? 'ReRate Book' : ' Rate Book'}
            </Link>

            <div className="modal fade" id={modalId} tabindex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content rounded-4">
                        <div className="modal-header d-flex justify-content-center p-0">
                            <h1 className="modal-title fs-5 bg-primary text-white px-3 py-1 rounded-bottom-4 mb-3" id={modalId + "Label"}>Rating</h1>
                        </div>
                        <div className="modal-body">
                            <h3 className='fw-bold'>Rate This <span className='text-danger'>Book</span></h3>
                            <p>Let us know what do you feel about the book you just
                                completed.</p>

                            <>
                                <div className="container py-5">
                                    <div className="row mb-3">
                                        <div className="col text-center">
                                            <Rating
                                                initialRating={rating}
                                                fullSymbol={Icons.rating(' text-warning', {})}
                                                emptySymbol={Icons.ratingOutline(' text-muted', {})}
                                                onChange={setRating}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col text-center">
                                        <button type="button" className="btn btn-dark mx-auto px-5 rounded-3" onClick={onSubmit} >
                                            {/* <button type="button" className="btn btn-dark mx-auto px-5 rounded-3" onClick={onSubmit} disabled={response.isLoading}> */}
                                            {
                                                // response.isLoading ?
                                                //     <>
                                                //         <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
                                                //         <span role="status">Submitting</span>
                                                //     </>
                                                //     :
                                                "Submit"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </>



                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary text-white rounded-pill px-4" data-bs-dismiss="modal" >Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateRatingModal